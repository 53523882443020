import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LABELS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { AddBankDocGlobalView } from "./bankStatements/AddBankDocGlobalView";
import { BankDocDetailsView } from "./bankStatements/BankDocDetailsView";
import { BankDocsListView } from "./bankStatements/BankDocsListView";

export const DocumentsView: React.FC<unknown> = () => {
  const { index: indexName } = ROUTE_NAMES.documents.bankDocs;

  return (
    <PageLayout title={LABELS.features.documents}>
      <div className={"tabs my-8"}>
        <CustomLink to={indexName}>{LABELS.features.bankDocs}</CustomLink>
      </div>

      <Routes>
        <Route
          index
          element={<Navigate to={`./${indexName}`} replace={true} />}
        />
        <Route path={indexName} element={<Outlet />}>
          <Route index element={<BankDocsListView />} />
          <Route path={"add"} element={<AddBankDocGlobalView />} />
          <Route path={"details/:bankDocId"} element={<BankDocDetailsView />} />
        </Route>
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
