import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import React from "react";
import { Outlet } from "react-router-dom";

import { reactPlugin } from "../../app/insights/AppInsightsPlugin";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { ErrorFallback } from "../../components/errors/ErrorFallback";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { MainSidebarNav } from "./MainSidebarNav";
import { MainTopNav } from "./MainTopNav";

export const MainView: React.FC<unknown> = () => {
  // xstate
  const { userAuthService, userInfoService } = React.useContext(GlobalContext);
  const [userAuthState] = Xstate.useActor(userAuthService);
  const { userAuthInfo } = userAuthState.context;
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  const userEmail = userAuthInfo?.email;
  const locationId = currentLocation?.id;

  if (!userEmail) {
    return <LottieLoading />;
  }

  return (
    <div className={"flex flex-col justify-start"}>
      <MainTopNav />
      <div className={"flex h-[93vh] flex-grow justify-start"}>
        {/* side-nav sets the location */}
        <MainSidebarNav />

        {/* render content only if location id is not empty */}
        {!!locationId && (
          <div className={"w-full overflow-auto"} id={"proc-main-view"}>
            <div className={"mx-auto min-h-screen w-full bg-gray-100 p-4"}>
              <AppInsightsErrorBoundary
                onError={ErrorFallback}
                appInsights={reactPlugin}>
                {/* render main route */}
                <Outlet />
              </AppInsightsErrorBoundary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
