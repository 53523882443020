import React from "react";
import { match } from "ts-pattern";

type Props = {
  name: string;
  description?: string;
  showHeading?: boolean;
  RightSideComponent?: JSX.Element;
  viewFormat?: "compact" | "normal" | "custom";
  cardBodyExtraCx?: string;
  extraCx?: string;
  customCx?: string;
};

export const FormSection: React.FC<Props> = (props) => {
  const {
    name,
    description,
    showHeading = true,
    RightSideComponent,
    viewFormat = "normal",
    cardBodyExtraCx,
    children,
    extraCx = "",
    customCx = "",
  } = props;

  const cardCx = match(viewFormat)
    .with("compact", () => "my-0 py-1")
    .with("normal", () => "my-6 py-6")
    .with("custom", () => customCx)
    .exhaustive();

  return (
    <div
      className={`card card-body card-bordered border-base-300 bg-base-100 ${cardCx} ${extraCx}`}>
      {showHeading && (
        <div className={"flex flex-row justify-between"}>
          <div className={"flex-col space-y-2 pb-6"}>
            <div className={" text-lg font-medium"}>{name}</div>
            {description && (
              <div className={"text-sm opacity-90"}>{description}</div>
            )}
          </div>
          <div>{RightSideComponent && RightSideComponent}</div>
        </div>
      )}

      <div className={`space-y-4 ${cardBodyExtraCx}`}>{children}</div>
    </div>
  );
};
