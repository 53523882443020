import { useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetOrgById,
  GetOrgByIdVariables,
} from "../../../generated/operation-result-types";
import { GET_ORG_BY_ID_GQL } from "../../../queries/OrgQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const OrgDetailsView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Org settings");
  });

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data,
  } = useQuery<GetOrgById, GetOrgByIdVariables>(GET_ORG_BY_ID_GQL, {
    variables: {
      orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
    },
  });

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("orgDetailsView | orgDetailsView", { error: errorQ });
    throw new Error("Error getting OrgDetailsView");
  }

  const orgResponse = data?.orgById;
  if (!orgResponse) {
    return <p>Missing org edges</p>;
  }

  const editOrgInfo = (
    <Link
      to={"./edit"}
      className={"link link-primary text-sm font-semibold uppercase"}>
      Edit
    </Link>
  );

  return (
    <>
      <FormSection name={"Company"} RightSideComponent={editOrgInfo}>
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Business Name</div>
          <div className={"text-md font-semibold opacity-80"}>
            {orgResponse.legalName}
          </div>
        </div>
      </FormSection>
    </>
  );
};
