import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import React from "react";

import { FormSection } from "../../../support/FormSection";
import { StripeCheckoutForm } from "./StripeCheckoutForm";

type Props = {
  setupIntentId?: string | null;
  publishableKey: string;
  onSuccess: () => void;
};

export const StripeSetSubscription: React.FC<Props> = ({
  setupIntentId,
  publishableKey,
  onSuccess,
}) => {
  const [stripePromise, setStripePromise] = React.useState<Stripe | null>(null);

  React.useEffect(() => {
    async function fun() {
      const sp = await loadStripe(publishableKey);
      setStripePromise(sp);
    }

    fun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!setupIntentId) {
    console.error("StripeSubscription | StripeSetSubscription", {
      setupIntentId,
    });
    throw new Error("StripeSubscription missing intent");
  }

  const spOptions: StripeElementsOptions = {
    clientSecret: setupIntentId,
    // appearance: {},
  };

  return (
    <FormSection name={"Payment Information"}>
      <Elements stripe={stripePromise} options={spOptions}>
        <StripeCheckoutForm
          setupIntentId={setupIntentId}
          onSuccess={onSuccess}
        />
      </Elements>
    </FormSection>
  );
};
