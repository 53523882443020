import { useMutation } from "@apollo/client";
import * as Apollo from "@apollo/client";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getFormattedCurrency } from "../../../app/commonOps/CurrencyOps";
import { INTERNAL, LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { BrandButton } from "../../../components/button/BrandButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  AddBdLicenses,
  AddBdLicensesInput,
  AddBdLicensesVariables,
  GetOrgBilling,
  GetOrgBillingVariables,
} from "../../../generated/operation-result-types";
import {
  ADD_BD_LICENSES_GQL,
  GET_ORG_BILLING_GQL,
} from "../../../queries/OrgBillingQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const AddBdLicensesView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Add licenses");
  });

  const navigate = useNavigate();

  // app insights
  const appInsights = useAppInsightsContext();
  const trackBdAddLicenses = useTrackEvent(
    appInsights,
    "Purchased additional licenses",
    {},
  );

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orgId = userInfoByEmail!.org!.id;

  const minCustomerQuantity = 1;
  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    watch,
  } = useForm<AddBdLicensesInput>({
    defaultValues: {
      orgId,
      customerQuantity: minCustomerQuantity,
    },
  });

  // gql
  const [addLicenseM, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<AddBdLicenses, AddBdLicensesVariables>(ADD_BD_LICENSES_GQL, {});

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetOrgBilling, GetOrgBillingVariables>(
    GET_ORG_BILLING_GQL,
    {
      fetchPolicy: "network-only",
      variables: {
        orgId: orgId,
      },
    },
  );

  // local effects
  React.useEffect(() => {
    if (dataQ) {
      setFocus("customerQuantity");
    }
  }, [dataQ, setFocus]);

  // watch and change price
  const [currentTotal, setCurrentTotal] = React.useState(
    getFormattedCurrency(0),
  );
  const [additionalTotal, setAdditionalTotal] = React.useState(
    getFormattedCurrency(0),
  );
  const [newTotal, setNewTotal] = React.useState(getFormattedCurrency(0));
  const watchCustomerQuantity = watch("customerQuantity");
  React.useEffect(() => {
    const orgBilling = _.first(dataQ?.orgBillings?.edges)?.node;
    if (!orgBilling) return;
    const { subscriptionPlan, billingCycleUsage } = orgBilling;

    const currency = subscriptionPlan.currency;
    const currentTotalAmount =
      subscriptionPlan.primaryAmount *
      billingCycleUsage.totalBdCustomersPurchased;
    const additionalTotalAmount =
      subscriptionPlan.primaryAmount * watchCustomerQuantity;
    const newTotalAmount = currentTotalAmount + additionalTotalAmount;
    setCurrentTotal(getFormattedCurrency(currentTotalAmount, currency));

    if (watchCustomerQuantity >= minCustomerQuantity) {
      setAdditionalTotal(getFormattedCurrency(additionalTotalAmount, currency));
      setNewTotal(getFormattedCurrency(newTotalAmount, currency));
    }
  }, [dataQ, watchCustomerQuantity]);

  // loading
  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("AddBdLicensesView | AddBdLicensesView", { errorQ });
    throw new Error("Error getting AddBdLicensesView");
  }

  const orgBilling = _.first(dataQ?.orgBillings?.edges)?.node;
  if (!orgBilling) {
    throw new Error("Missing billing info");
  }

  const { billingCycleUsage } = orgBilling;
  const currentLicenseCount = billingCycleUsage.totalBdCustomersPurchased;

  const onSubmit: SubmitHandler<AddBdLicensesInput> = async (data) => {
    resetM();
    await addLicenseM({
      variables: {
        addBdLicensesInput: data,
      },
    });
    trackBdAddLicenses({
      orgId: orgId,
      customerQuantity: data.customerQuantity,
    });
    if (!errorM) {
      navigate("../billing");
    }
  };

  const purchaseView = (
    <FormSection name={"Additional Customer Licenses"}>
      <div className={"flex justify-between"}>
        <div className={"text-base-content opacity-60"}>
          Current monthly cost for {currentLicenseCount} customer licenses
        </div>
        <div className={"text-md font-semibold opacity-80"}>{currentTotal}</div>
      </div>

      <div className={"flex items-center justify-between"}>
        <div className={"text-base-content opacity-60"}>
          Additional customer license quantity
        </div>
        <div className={"flex flex-col"}>
          <input
            type={"number"}
            {...register("customerQuantity", {
              min: {
                value: minCustomerQuantity,
                message: `License quantity must be at least ${minCustomerQuantity}`,
              },
              valueAsNumber: true,
              required: {
                value: true,
                message: "License quantity is required",
              },
            })}
            className={"input input-bordered"}
          />
          {errors?.customerQuantity?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.customerQuantity?.message}
            </span>
          )}
        </div>
      </div>

      <div className={"flex justify-between"}>
        <div className={"text-md font-semibold opacity-80"}>
          Monthly cost for {watchCustomerQuantity} customer licenses (before
          taxes)
        </div>
        <div className={"text-md font-semibold opacity-80"}>
          {additionalTotal}
        </div>
      </div>

      <div className={"flex justify-between"}>
        <div className={"text-md font-semibold opacity-80"}>
          New monthly cost for {currentLicenseCount} current +{" "}
          {watchCustomerQuantity} additional customer licenses (before taxes)
        </div>
        <div className={"text-md font-semibold opacity-80"}>{newTotal}</div>
      </div>

      {errorM && <Alert type={"error"} label={LABELS.errors.default} />}

      <BrandButton
        buttonType={"submit"}
        colorType={"primary"}
        label={"Confirm"}
        disabled={loadingM}
      />
    </FormSection>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>{purchaseView}</form>
      <a
        href={`mailto:${INTERNAL.emails.support}?subject=License%20removal%20request`}
        className={"btn btn-error btn-outline"}>
        Remove licenses
      </a>
    </>
  );
};
