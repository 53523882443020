import React from "react";
import { match } from "ts-pattern";

type Props = {
  modalId: string;
  modalButtonLabel: string;
  modalButtonStyle?: "secondary" | "link";
};

export const ModalWithClose = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>((props, ref) => {
  const {
    modalId,
    modalButtonLabel,
    modalButtonStyle = "secondary",
    children,
  } = props;

  const derivedButtonStyle = match(modalButtonStyle)
    .with("secondary", () => "btn btn-outline btn-secondary")
    .with("link", () => "btn btn-primary btn-link")
    .exhaustive();

  return (
    <React.Fragment>
      <label htmlFor={modalId} className={`modal-button ${derivedButtonStyle}`}>
        {modalButtonLabel}
      </label>
      <input
        type={"checkbox"}
        ref={ref}
        id={modalId}
        className={"modal-toggle"}
      />
      <div className={"modal"}>
        <div className={"modal-box relative"}>
          <label
            htmlFor={modalId}
            className={
              "btn btn-secondary btn-circle btn-sm absolute right-2 top-2"
            }>
            ✕
          </label>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
});
