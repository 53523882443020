import React from "react";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "./SvgIconProps";

export const CustomersSvg: React.FC<SvgIconProps> = (props) => {
  const { sizeCx = `${TW_SIZES.hw.md} ${TW_SIZES.mr.md}` } = props;

  return (
    <>
      <svg
        width={"24"}
        height={"24"}
        viewBox={"0 0 24 24"}
        fill={"none"}
        xmlns={"http://www.w3.org/2000/svg"}
        className={`inline-block ${sizeCx} fill-current stroke-current`}>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M9.49 14.69C8.76 14.26 7.91 14 7 14C4.24 14 2 16.24 2 19H8C8 17.37 8.56 15.88 9.49 14.69Z"
          }></path>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M7 14C8.38071 14 9.5 12.8807 9.5 11.5C9.5 10.1193 8.38071 9 7 9C5.61929 9 4.5 10.1193 4.5 11.5C4.5 12.8807 5.61929 14 7 14Z"
          }></path>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M15 12C11.13 12 8 15.13 8 19C8.01 19 22 19 22 19C22 15.13 18.87 12 15 12Z"
          }></path>
        <path
          strokeLinejoin={"round"}
          strokeLinecap={"round"}
          strokeMiterlimit={"10"}
          strokeWidth={"1"}
          fill={"none"}
          d={
            "M15 12C16.933 12 18.5 10.433 18.5 8.5C18.5 6.567 16.933 5 15 5C13.067 5 11.5 6.567 11.5 8.5C11.5 10.433 13.067 12 15 12Z"
          }></path>
      </svg>
    </>
  );
};
