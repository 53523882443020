import * as Apollo from "@apollo/client";
import { NetworkStatus } from "@apollo/client";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import { v4 } from "uuid";

import { isEmptyStr, setEmptyOrStr } from "../../app/commonOps/CommonStringOps";
import { PAGE_LIMIT } from "../../app/constants/PaginationConstants";
import { LABELS, PATHS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { IconButton } from "../../components/button/IconButton";
import { OutlineButton } from "../../components/button/OutlineButton";
import { EmptyDataView } from "../../components/emptyStates/EmptyDataView";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { ModalWithClose } from "../../components/modal/ModalWithClose";
import { WarningExclaimSvg } from "../../components/svg/WarningExclaimSvg";
import {
  CustomerFilterInput,
  CustomerSortInput,
  GetCustomersByLocation,
  GetCustomersByLocationVariables,
  SortEnumType,
} from "../../generated/operation-result-types";
import { GET_CUSTOMERS_BY_LOCATION_GQL } from "../../queries/CustomerQueries.gql";
import { FormSection } from "../../support/FormSection";
import { PlanLimitsBannerView } from "../banners/PlanLimitsBannerView";

export const DeactivatedCustomersListView: React.FC<unknown> = () => {
  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  // state - sorting
  type SortOrderT = "accountName";
  const [selectedSortOrder, setSelectedSortOrder] =
    React.useState<SortOrderT>("accountName");

  const querySortOrder = match(selectedSortOrder)
    .with("accountName", (): CustomerSortInput => {
      return { name: SortEnumType.ASC };
    })
    .exhaustive();

  // state - filtering
  type FilterStateT = {
    customerName?: string;
  };
  const initialFilterState: CustomerFilterInput = {
    deletedAt: { neq: null },
    location: {
      id: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        eq: currentLocation!.id,
      },
    },
  };
  const [filterState, setFilterState] = React.useState(initialFilterState);

  // RHF
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FilterStateT>({
    defaultValues: {},
  });

  // functions - filter
  const filterModalId = v4();

  const filterModalCheckRef = React.createRef<HTMLInputElement>();
  const filterOnSubmit: SubmitHandler<FilterStateT> = (data) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        name: data.customerName ? { contains: data.customerName } : undefined,
      };
    });
  };
  const clearFilterState = () => {
    setFilterState(initialFilterState);
    reset();
  };
  React.useEffect(() => {
    if (filterModalCheckRef.current) {
      filterModalCheckRef.current.checked = false;
    }
  }, [filterModalCheckRef, filterState]);

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
    fetchMore,
    networkStatus,
  } = Apollo.useQuery<GetCustomersByLocation, GetCustomersByLocationVariables>(
    GET_CUSTOMERS_BY_LOCATION_GQL,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        first: PAGE_LIMIT,
        order: [querySortOrder],
        where: filterState,
      },
    },
  );

  const isRefetching = networkStatus === NetworkStatus.refetch;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoadingOrFetching = (loadingQ && !dataQ) || isRefetching;

  if (errorQ) {
    console.error("CustomersListView | CustomersListView", { errorQ });
    throw new Error("Error getting CustomersListView");
  }

  if (isLoadingOrFetching) {
    return <LottieLoading />;
  }

  const customers = dataQ?.customers;
  const customerEdges = customers?.edges;
  const edgesEmpty = !customerEdges || customerEdges.length < 1;

  const loadMore = () => {
    fetchMore({
      variables: {
        after: customers?.pageInfo.endCursor,
      },
    });
  };

  const customerRows = customerEdges?.map(({ node: customerNode }) => {
    const { id, name, contact, deletedAt } = customerNode;
    const isActive = !deletedAt;

    const customerColumn = () => {
      return (
        <div className={"flex items-center space-x-3"}>
          <div className={"flex items-center"}>
            <Link
              to={PATHS.customerById(id)}
              className={"link link-primary text-sm font-semibold uppercase"}>
              {name}
            </Link>
            {!isActive && (
              <div
                className={"tooltip tooltip-bottom tooltip-warning"}
                data-tip={LABELS.warnings.inactiveTooltip}>
                <IconButton
                  size={"extra-small"}
                  colorType={"secondary"}
                  border={false}
                  extraCx={"ml-1 mt-1 rounded-full"}
                  IconSvg={WarningExclaimSvg}
                />
              </div>
            )}
          </div>
        </div>
      );
    };

    const contactNameColumn = () => {
      if (!contact) return LABELS.empty.default;

      const { fullName, avatarInitials } = contact;
      const defaultedFullName = isEmptyStr(fullName)
        ? LABELS.empty.default
        : fullName;

      return (
        <div className={"flex items-center space-x-3"}>
          <AvatarPlaceholder size={"extra-small"} label={avatarInitials} />
          <div>{`${defaultedFullName}`}</div>
        </div>
      );
    };

    const contactPhoneNumberColumn = () => {
      if (!contact) return null;

      const { phoneNumbers } = contact;
      const phoneNumber = _.first(phoneNumbers)?.number ?? LABELS.empty.default;

      return phoneNumber && <div>{phoneNumber}</div>;
    };

    const contactEmailColumn = () => {
      if (!contact) return null;

      const { email } = contact;

      return (
        email && (
          <a
            href={`mailto:${email.emailAddress}`}
            className={"link link-primary"}>
            {email.emailAddress}
          </a>
        )
      );
    };

    return (
      <tr key={id}>
        <td>{customerColumn()}</td>
        <td>{contactNameColumn()}</td>
        <td>{contactPhoneNumberColumn()}</td>
        <td>{contactEmailColumn()}</td>
      </tr>
    );
  });

  const filterBtnView = (
    <div>
      <ModalWithClose
        ref={filterModalCheckRef}
        modalId={filterModalId}
        modalButtonLabel={LABELS.filters.name}>
        <div className={"py-8"}>
          <FormSection name={LABELS.filters.name}>
            <form
              onSubmit={handleSubmit(filterOnSubmit)}
              className={"flex flex-col items-center"}>
              <div
                className={
                  "form-control grid w-full grid-cols-2 items-center pb-2"
                }>
                <label className={"label"}>
                  <span className={"label-text"}>Customer / Company name</span>
                </label>
                <input
                  className={"input input-bordered"}
                  {...register("customerName", {
                    required: false,
                    setValueAs: setEmptyOrStr,
                  })}
                />
                {errors?.customerName?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.customerName?.message}
                  </span>
                )}
              </div>

              <div className={"space-x-2 pt-2"}>
                <OutlineButton
                  buttonType={"submit"}
                  colorType={"primary"}
                  label={LABELS.filters.apply}
                />
                <OutlineButton
                  colorType={"secondary"}
                  label={LABELS.filters.reset}
                  onClick={clearFilterState}
                />
              </div>
            </form>
          </FormSection>
        </div>
      </ModalWithClose>
    </div>
  );
  const sortBtnView = (
    <div className={"dropdown-end dropdown"}>
      <div tabIndex={0} className={"btn btn-outline m-1"}>
        {LABELS.sort.name}
      </div>
      <ul
        tabIndex={0}
        className={
          "dropdown-content menu rounded-box w-52 bg-base-100 p-2 shadow"
        }>
        <li>
          <div
            onClick={(e) => {
              e.preventDefault();
              setSelectedSortOrder("accountName");
            }}>
            First name
          </div>
        </li>
      </ul>
    </div>
  );
  const paginationView = (
    <div className={"flex justify-end py-8"}>
      <div className={"btn-group"}>
        {customers?.pageInfo.hasNextPage && (
          <OutlineButton
            colorType={"secondary"}
            label={`Load ${PAGE_LIMIT} more`}
            onClick={loadMore}
            disabled={isFetchingMore}
          />
        )}
      </div>
    </div>
  );

  const customersTableView = (
    <table className={"table w-full"}>
      <thead>
        <tr>
          <th>{LABELS.features.customer} / Company</th>
          <th>{LABELS.features.contact}</th>
          <th>Phone Number</th>
          <th>Email Address</th>
        </tr>
      </thead>
      <tbody>{customerRows}</tbody>
    </table>
  );

  return (
    <FormSection name={`${LABELS.features.deactivatedCustomers}`}>
      <PlanLimitsBannerView />
      <div
        className={
          "card card-body my-6 overflow-x-auto border-base-300 bg-base-100 px-0"
        }>
        <div className={"flex justify-between pb-4"}>
          {filterBtnView}
          {sortBtnView}
        </div>

        {edgesEmpty ? <EmptyDataView /> : customersTableView}

        {paginationView}
      </div>
    </FormSection>
  );
};
