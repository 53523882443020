import React from "react";

type Props = {
  address: {
    id: string;
    line1?: string | null;
    line2?: string | null;
    city: string;
    country: string;
    region: string;
    postalCode: string;
  };
};

export const AddressComponent: React.FC<Props> = (props) => {
  const { id, line1, line2, city, region, country, postalCode } = props.address;
  const fullAddress = [line1, line2, city, region, country, postalCode]
    .filter((e) => !!e?.trim())
    .map((e) => {
      return (
        <React.Fragment key={e}>
          {e}
          <br />
        </React.Fragment>
      );
    });
  return <React.Fragment key={id}>{fullAddress}</React.Fragment>;
};
