import * as Xstate from "@xstate/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { LABELS, PATHS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import { BrandButton } from "../../components/button/BrandButton";
import { LogoLink } from "../../components/logos/LogoLink";
import { SignOutSvg } from "../../components/svg/SignOutSvg";

export const MainTopNav: React.FC<unknown> = () => {
  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  return (
    <div className={"navbar sticky top-0 z-50 h-[5vh] bg-primary"}>
      {/* hamburger icon in small view */}
      {/*<div className={"flex-none"}>*/}
      {/*  <label htmlFor={"my-drawer-1"} className={CX.drawerMenu}>*/}
      {/*    <HamburgerSvg sizeCx={TW_SIZES.hw.lg} />*/}
      {/*  </label>*/}
      {/*  <LogoLink />*/}
      {/*</div>*/}

      {/* logo in small view */}
      <div
        className={"inset-x-0 z-50 block flex-1 border-base-200 bg-base-100"}>
        <div className={"mx-auto max-w-none space-x-1 bg-primary"}>
          <div className={"flex flex-none"}>
            <LogoLink />
          </div>
        </div>
      </div>
      <div className={"flex-none"}>
        <div className={"dropdown-end dropdown"}>
          <label tabIndex={0} className={"avatar btn btn-ghost btn-circle"}>
            <div className={"w-10 rounded-full"}>
              <AvatarPlaceholder
                size={"extra-small"}
                label={
                  userInfoByEmail?.userProfile?.avatarInitials ??
                  LABELS.unknownUser.defaultInitials
                }
              />
            </div>
          </label>
          <ul
            tabIndex={0}
            className={
              "dropdown-content menu rounded-box menu-compact mt-3 w-64 bg-base-100 p-2 shadow"
            }>
            <li></li>
            <li>
              <a href={PATHS.user()}>
                <div className={"flex flex-col"}>
                  <div>Profile</div>
                </div>
              </a>
            </li>
            <li>
              <BrandButton
                colorType={"ghost"}
                label={LABELS.features.signout}
                SvgIconLeft={SignOutSvg}
                onClick={() => navigate(ROUTE_NAMES.signOut)}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
