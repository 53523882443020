import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import React from "react";

export const StripeCardSection: React.FC<unknown> = () => {
  const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <div className={"max-w-lg"}>
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onReady={(e) => e.focus()}
        />
      </div>
      <div className={"py-3 text-sm opacity-50"}>
        By providing your card information, you allow ProCharted Inc. to charge
        your card for future payments in accordance with their terms.
      </div>
    </>
  );
};
