import React from "react";

export default function PushingButtons() {
  return (
    <>
      <svg
        viewBox={"0 0 800 600"}
        fill={"none"}
        className={"h-1/2 w-full"}
        xmlns={"http://www.w3.org/2000/svg"}>
        <rect
          x={"685.039"}
          y={"31.1943"}
          width={"371.745"}
          height={"689.798"}
          rx={"48.8571"}
          transform={"rotate(75 685.039 31.1943)"}
          fill={"#B5CDFB"}></rect>
        <rect
          x={"107.851"}
          y={"121.116"}
          width={"601.299"}
          height={"369.767"}
          rx={"43.3261"}
          fill={"#b197fc"}></rect>
        <rect
          x={"462.89"}
          y={"234.156"}
          width={"205.38"}
          height={"35.6388"}
          rx={"17.8194"}
          fill={"#5433ff"}></rect>
        <rect
          x={"462.89"}
          y={"293.348"}
          width={"205.38"}
          height={"35.6388"}
          rx={"17.8194"}
          fill={"#5433ff"}></rect>
        <ellipse
          cx={"501.352"}
          cy={"376.622"}
          rx={"17.8412"}
          ry={"17.8194"}
          fill={"#FFE458"}></ellipse>
        <ellipse
          cx={"565.58"}
          cy={"376.622"}
          rx={"17.8412"}
          ry={"17.8194"}
          fill={"#FFE458"}></ellipse>
        <ellipse
          cx={"629.809"}
          cy={"376.622"}
          rx={"17.8412"}
          ry={"17.8194"}
          fill={"#FFE458"}></ellipse>
        <ellipse
          cx={"501.352"}
          cy={"433.644"}
          rx={"17.8412"}
          ry={"17.8194"}
          fill={"#FFE458"}></ellipse>
        <ellipse
          cx={"565.58"}
          cy={"433.644"}
          rx={"17.8412"}
          ry={"17.8194"}
          fill={"#FFE458"}></ellipse>
        <ellipse
          cx={"629.809"}
          cy={"433.644"}
          rx={"17.8412"}
          ry={"17.8194"}
          fill={"#FFE458"}></ellipse>
        <rect
          x={"138.018"}
          y={"153.468"}
          width={"540.966"}
          height={"35.6387"}
          rx={"2.86"}
          fill={"#5433ff"}></rect>
        <rect
          x={"159.001"}
          y={"213.417"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"244.697"}
          y={"213.417"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"330.394"}
          y={"213.417"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"159.001"}
          y={"299.113"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"244.697"}
          y={"299.113"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"330.394"}
          y={"299.113"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"159.001"}
          y={"384.809"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"244.697"}
          y={"384.809"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <rect
          x={"330.394"}
          y={"384.809"}
          width={"64.7321"}
          height={"64.7321"}
          rx={"12"}
          fill={"#5433ff"}></rect>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M274.677 255.221L272.166 261.359L278.313 258.853L279.071 258.096L280.283 259.307L279.265 260.323L271.003 263.442C270.426 263.66 269.862 263.096 270.08 262.52L273.202 254.268L274.22 253.251L275.435 254.464L274.677 255.221ZM278.654 262.713C278.654 262.239 279.039 261.855 279.513 261.855H289.252C289.726 261.855 290.111 262.239 290.111 262.713C290.111 263.187 289.726 263.572 289.252 263.572H279.513C279.039 263.572 278.654 263.187 278.654 262.713ZM292.402 261.855C291.928 261.855 291.543 262.239 291.543 262.713C291.543 263.187 291.928 263.572 292.402 263.572H294.98C295.455 263.572 295.839 263.187 295.839 262.713C295.839 262.239 295.455 261.855 294.98 261.855H292.402Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M290.853 247.519C291.186 247.852 291.727 247.852 292.06 247.519L294.533 245.049C296.207 243.377 296.207 240.666 294.533 238.994C292.859 237.322 290.144 237.322 288.47 238.994L281.928 245.527C281.593 245.862 281.593 246.404 281.928 246.739C282.263 247.073 282.806 247.073 283.141 246.739L289.672 240.216C290.678 239.211 292.309 239.211 293.314 240.216C294.32 241.22 294.32 242.849 293.314 243.854L290.853 246.313C290.519 246.646 290.519 247.186 290.853 247.519ZM273.785 253.661C273.742 253.704 273.704 253.75 273.673 253.799L273.207 254.264L273.663 254.719C273.697 254.774 273.738 254.825 273.785 254.872C273.832 254.919 273.883 254.96 273.938 254.994L278.513 259.564C278.547 259.618 278.588 259.669 278.635 259.716C278.682 259.764 278.734 259.804 278.788 259.838L279.27 260.319L280.491 259.099L280.479 259.086L289.323 250.252C289.658 249.918 289.658 249.376 289.323 249.041C288.989 248.707 288.446 248.707 288.111 249.041L279.266 257.875L275.628 254.242L280.401 249.475C280.736 249.14 280.736 248.598 280.401 248.264C280.067 247.929 279.524 247.929 279.189 248.264L273.785 253.661Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M358.183 237.334C357.362 237.334 356.696 237.999 356.696 238.819V262.578C356.696 263.398 357.362 264.063 358.183 264.063H376.022C376.843 264.063 377.509 263.398 377.509 262.578V256.044C377.509 255.552 377.109 255.153 376.617 255.153C376.124 255.153 375.725 255.552 375.725 256.044V258.123V261.539C375.725 261.949 375.392 262.281 374.981 262.281H359.224C358.813 262.281 358.48 261.949 358.48 261.539V239.858C358.48 239.448 358.813 239.116 359.224 239.116H374.981C375.392 239.116 375.725 239.448 375.725 239.858V240.304V240.754C375.725 241.246 376.124 241.645 376.617 241.645C377.109 241.645 377.509 241.246 377.509 240.754V238.819C377.509 237.999 376.843 237.334 376.022 237.334H358.183ZM361.156 243.274C361.156 242.454 361.822 241.789 362.643 241.789H371.562C372.383 241.789 373.049 242.454 373.049 243.274C373.049 244.094 372.383 244.759 371.562 244.759H362.643C361.822 244.759 361.156 244.094 361.156 243.274ZM362.643 249.214C361.822 249.214 361.156 249.878 361.156 250.699C361.156 251.519 361.822 252.183 362.643 252.183H366.508L369.481 249.214H362.643Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M383.369 241.114C382.207 239.954 380.325 239.954 379.164 241.114L366.674 253.591C366.592 253.672 366.531 253.771 366.494 253.881L364.917 258.606C364.724 259.186 365.277 259.738 365.858 259.545L370.588 257.97C370.697 257.933 370.797 257.872 370.878 257.791L378.853 249.825C379.181 249.497 379.182 248.966 378.854 248.638C378.526 248.308 377.992 248.308 377.663 248.636L377.503 248.796L377.503 248.796L369.636 256.654L367.215 257.585C367.094 257.631 366.976 257.513 367.023 257.393L367.954 254.975L380.568 242.374C381.032 241.91 381.785 241.91 382.25 242.374C382.714 242.838 382.714 243.59 382.25 244.054L379.876 246.425C379.575 246.757 379.586 247.269 379.906 247.587C380.235 247.914 380.767 247.913 381.095 247.585L383.369 245.314C384.53 244.154 384.53 242.274 383.369 241.114Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M360.443 339.539C360.443 337.959 361.726 336.678 363.308 336.678H377.628C379.21 336.678 380.493 337.959 380.493 339.539V350.982C380.493 352.562 379.21 353.842 377.628 353.842H363.308C361.726 353.842 360.443 352.562 360.443 350.982V341.827V339.539ZM371.041 342.686H362.162V350.695C362.162 351.485 362.803 352.126 363.594 352.126H377.342C378.133 352.126 378.774 351.485 378.774 350.695V339.825C378.774 339.035 378.133 338.395 377.342 338.395H363.594C362.803 338.395 362.162 339.035 362.162 339.825V340.969H371.041C371.515 340.969 371.9 341.353 371.9 341.827C371.9 342.301 371.515 342.686 371.041 342.686ZM370.468 346.691C369.677 346.691 369.036 347.331 369.036 348.121V349.551C369.036 350.341 369.677 350.982 370.468 350.982C371.259 350.982 371.9 350.341 371.9 349.551V348.121C371.9 347.331 371.259 346.691 370.468 346.691ZM373.475 340.969C373.001 340.969 372.616 341.353 372.616 341.827C372.616 342.301 373.001 342.686 373.475 342.686H376.053C376.528 342.686 376.912 342.301 376.912 341.827C376.912 341.353 376.528 340.969 376.053 340.969H373.475Z"
          }
          fill={"#b197fc"}></path>
        <path
          d={
            "M363.308 329.527C363.308 325.577 366.513 322.375 370.468 322.375C374.423 322.375 377.628 325.577 377.628 329.527V334.89C377.628 335.483 377.148 335.963 376.554 335.963C375.961 335.963 375.48 335.483 375.48 334.89V329.527C375.48 326.762 373.236 324.52 370.468 324.52C367.7 324.52 365.456 326.762 365.456 329.527V334.89C365.456 335.483 364.975 335.963 364.382 335.963C363.789 335.963 363.308 335.483 363.308 334.89V329.527Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M196.574 351.805C196.012 352.6 194.847 352.612 194.288 351.815C191.658 348.067 185.409 338.689 185.409 334.171C185.409 328.449 189.897 323.81 195.434 323.81C200.97 323.81 205.458 328.449 205.458 334.171C205.458 336.371 204.348 339.435 202.126 343.362C201.994 343.595 201.796 343.934 201.61 344.249C201.344 344.7 200.75 344.823 200.324 344.519C199.97 344.265 199.85 343.793 200.041 343.402L200.299 342.873C202.113 339.7 203.74 336.27 203.74 334.171C203.74 329.43 200.021 325.586 195.434 325.586C190.846 325.586 187.128 329.43 187.128 334.171C187.128 338.154 192.988 346.933 194.864 349.642C195.142 350.044 195.726 350.044 196.004 349.642C196.453 348.993 197.13 347.998 197.901 346.803L198.422 346.059C198.705 345.655 199.266 345.563 199.663 345.855C200.038 346.13 200.134 346.65 199.878 347.038C199.679 347.342 199.447 347.692 199.258 347.972C198.71 348.781 197.49 350.512 196.574 351.805Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M199.73 334.172C199.73 336.624 197.807 338.612 195.434 338.612C193.061 338.612 191.138 336.624 191.138 334.172C191.138 331.719 193.061 329.731 195.434 329.731C195.967 329.731 196.477 329.831 196.948 330.015C197.412 330.196 197.476 330.81 197.129 331.167L197.006 331.294C196.776 331.53 196.44 331.618 196.109 331.6C195.894 331.539 195.667 331.507 195.434 331.507C194.01 331.507 192.856 332.7 192.856 334.172C192.856 335.643 194.01 336.836 195.434 336.836C196.858 336.836 198.012 335.643 198.012 334.172C198.012 333.902 197.973 333.641 197.901 333.395C197.89 333.096 197.972 332.794 198.181 332.581C198.599 332.155 199.351 332.249 199.527 332.819C199.659 333.246 199.73 333.7 199.73 334.172Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M269.17 334.553C268.611 334.931 268.481 335.7 268.885 336.241C269.264 336.749 269.975 336.869 270.5 336.514L282.142 328.639C282.627 328.311 283.263 328.311 283.748 328.639L295.402 336.522C295.931 336.879 296.649 336.754 297.025 336.238C297.42 335.697 297.287 334.936 296.731 334.56L283.748 325.779C283.263 325.451 282.627 325.451 282.142 325.779L269.17 334.553ZM291.281 349.863V350.123C291.281 350.439 291.024 350.695 290.708 350.695H275.183C274.866 350.695 274.61 350.439 274.61 350.123V337.463C274.571 337.023 274.2 336.678 273.749 336.678C273.272 336.678 272.885 337.064 272.885 337.541V351.268C272.885 351.899 273.398 352.412 274.031 352.412H291.86C292.492 352.412 293.005 351.899 293.005 351.268V346.119C293.005 345.645 292.62 345.26 292.145 345.26C291.671 345.26 291.286 345.645 291.286 346.119V349.865L291.281 349.863ZM291.281 337.536C291.281 337.062 291.668 336.678 292.143 336.678C292.618 336.678 293.005 337.062 293.005 337.536V342.972C293.005 343.445 292.618 343.83 292.143 343.83C291.668 343.83 291.281 343.445 291.281 342.972V337.536Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M287.257 324.664C287.257 324.638 287.258 324.614 287.26 324.589C287.258 324.566 287.257 324.544 287.257 324.521C287.257 324.126 287.577 323.805 287.973 323.805H288.119H292.143H292.289C292.685 323.805 293.005 324.126 293.005 324.521C293.005 324.544 293.004 324.566 293.002 324.589C293.004 324.614 293.005 324.638 293.005 324.664V329.384C293.005 329.858 292.617 330.242 292.143 330.242C291.668 330.242 291.281 329.858 291.281 329.384V325.236H288.981V326.523C288.981 326.997 288.593 327.381 288.119 327.381C287.644 327.381 287.257 326.997 287.257 326.523V324.664ZM278.634 339.253C278.634 338.621 279.147 338.109 279.779 338.109H286.111C286.744 338.109 287.257 338.621 287.257 339.253V348.406C287.257 348.881 286.872 349.265 286.396 349.265C285.921 349.265 285.536 348.881 285.536 348.406V345.26H285.532V340.397C285.532 340.081 285.276 339.825 284.959 339.825H280.931C280.615 339.825 280.358 340.081 280.358 340.397V348.46C280.329 348.91 279.955 349.265 279.497 349.265C279.02 349.265 278.634 348.879 278.634 348.403V339.253Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M201.422 246.667C201.422 252.197 196.934 256.68 191.398 256.68C185.861 256.68 181.373 252.197 181.373 246.667C181.373 241.138 185.861 236.655 191.398 236.655C196.934 236.655 201.422 241.138 201.422 246.667ZM199.703 246.667C199.703 251.248 195.984 254.963 191.397 254.963C186.81 254.963 183.091 251.248 183.091 246.667C183.091 242.085 186.81 238.371 191.397 238.371C195.984 238.371 199.703 242.085 199.703 246.667Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M186.255 244.166C186.096 244.485 186.264 244.847 186.593 244.985C186.987 245.15 187.455 244.934 187.668 244.565C187.828 244.288 188.057 243.959 188.377 243.621C189.234 242.72 190.489 242.472 191.289 242.411C191.7 242.379 192.054 242.048 192.054 241.637C192.054 241.271 191.773 240.97 191.407 240.989C190.452 241.037 188.636 241.32 187.302 242.679C186.777 243.235 186.449 243.777 186.255 244.166ZM198.855 256.137C198.296 255.579 198.296 254.673 198.855 254.115C199.414 253.557 200.321 253.557 200.88 254.115L201.528 254.763C202.485 254.506 203.548 254.752 204.298 255.502L208.658 259.857C209.776 260.974 209.774 262.786 208.656 263.903C207.537 265.021 205.722 265.022 204.604 263.905L200.244 259.55C199.495 258.802 199.249 257.741 199.504 256.786L198.855 256.137ZM201.242 256.478C200.683 257.035 200.681 257.94 201.239 258.499L205.617 262.888C206.175 263.449 207.084 263.449 207.645 262.89C208.203 262.332 208.205 261.427 207.647 260.868L203.27 256.479C202.711 255.918 201.802 255.918 201.242 256.478Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M187.509 420.251L194.122 414.747C194.637 414.318 195.42 414.684 195.42 415.354V435.683C195.42 436.353 194.637 436.719 194.122 436.29L187.509 430.786H183.554C182.826 430.786 182.235 430.196 182.235 429.469V421.568C182.235 420.841 182.826 420.251 183.554 420.251H187.509ZM184.213 421.832C183.995 421.832 183.817 422.008 183.817 422.227V428.811C183.817 429.029 183.995 429.206 184.213 429.206H187.984L193.621 433.898C193.707 433.969 193.837 433.908 193.837 433.797V417.241C193.837 417.129 193.707 417.068 193.621 417.139L187.984 421.832H184.213ZM204.692 415.255C204.394 414.907 203.873 414.919 203.562 415.256C203.262 415.581 203.27 416.089 203.556 416.427C204.651 417.723 207.049 421.073 207.049 425.545C207.049 430.016 204.653 433.358 203.557 434.652C203.271 434.99 203.261 435.499 203.56 435.826C203.871 436.168 204.397 436.181 204.698 435.83C205.909 434.414 208.632 430.664 208.632 425.545C208.632 420.419 205.901 416.666 204.692 415.255ZM200.895 418.18C201.201 417.845 201.72 417.838 202.002 418.195C202.919 419.358 204.676 422.042 204.676 425.545C204.676 429.055 202.912 431.743 201.996 432.901C201.717 433.254 201.203 433.249 200.898 432.918C200.617 432.614 200.614 432.144 200.862 431.814C201.632 430.788 203.094 428.456 203.094 425.545C203.094 422.636 201.634 420.312 200.864 419.288C200.615 418.957 200.616 418.486 200.895 418.18ZM199.305 421.124C199.051 420.752 198.534 420.748 198.23 421.082C197.953 421.385 197.958 421.859 198.181 422.204C198.591 422.84 199.139 423.982 199.139 425.545C199.139 427.11 198.591 428.254 198.18 428.891C197.957 429.236 197.955 429.71 198.233 430.012C198.536 430.34 199.047 430.336 199.3 429.968C199.871 429.135 200.721 427.557 200.721 425.545C200.721 423.539 199.876 421.961 199.305 421.124Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M272.982 421.251C272.982 415.753 277.445 411.295 282.951 411.295C288.457 411.295 292.92 415.753 292.92 421.251V422.674C294.493 422.674 295.768 423.947 295.768 425.519V431.208C295.768 432.779 294.493 434.053 292.92 434.053V436.186C292.92 437.758 291.645 439.031 290.072 439.031H286.97C286.69 439.459 286.207 439.742 285.657 439.742H281.669C280.804 439.742 280.103 439.042 280.103 438.178C280.103 437.314 280.804 436.613 281.669 436.613H285.657C286.266 436.613 286.793 436.96 287.053 437.467H289.787C290.573 437.467 291.211 436.83 291.211 436.044V434.337V434.053H288.647V422.674H291.211V421.251C291.211 416.695 287.513 413.002 282.951 413.002C278.389 413.002 274.691 416.695 274.691 421.251V422.674H277.254V434.053H272.982C271.409 434.053 270.134 432.779 270.134 431.208V425.519C270.134 423.947 271.409 422.674 272.982 422.674V421.251ZM290.356 424.381H292.635C293.422 424.381 294.059 425.018 294.059 425.803V430.924C294.059 431.709 293.422 432.346 292.635 432.346H290.356V424.381ZM275.545 424.381H273.267C272.48 424.381 271.843 425.018 271.843 425.803V430.924C271.843 431.709 272.48 432.346 273.267 432.346H275.545V424.381Z"
          }
          fill={"#b197fc"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M359.921 412.351C359.921 411.623 360.511 411.034 361.239 411.034H363.876H365.195H367.831H369.15H371.787H373.105H375.742H377.06H379.697C380.425 411.034 381.016 411.623 381.016 412.351V414.984C381.016 415.711 380.425 416.301 379.697 416.301H379.628L378.445 438.756C378.408 439.455 377.829 440.004 377.128 440.004H363.809C363.107 440.004 362.529 439.455 362.492 438.756L361.309 416.301H361.239C360.511 416.301 359.921 415.711 359.921 414.984V412.351ZM361.767 414.984H363.876V412.351H361.767C361.475 412.351 361.239 412.586 361.239 412.877V414.457C361.239 414.748 361.475 414.984 361.767 414.984ZM365.195 414.984H367.831V412.351H365.195V414.984ZM369.15 414.984H371.787V412.351H369.15V414.984ZM373.105 414.984H375.742V412.351H373.105V414.984ZM377.06 414.984H379.17C379.461 414.984 379.697 414.748 379.697 414.457V412.877C379.697 412.586 379.461 412.351 379.17 412.351H377.06V414.984ZM362.558 416.301H378.379L377.097 437.804C377.077 438.152 376.788 438.423 376.439 438.423H364.497C364.148 438.423 363.86 438.152 363.839 437.804L362.558 416.301ZM370.468 420.251C370.104 420.251 369.809 420.546 369.809 420.91V435.395C369.809 435.758 370.104 436.053 370.468 436.053C370.832 436.053 371.128 435.758 371.128 435.395V420.91C371.128 420.546 370.832 420.251 370.468 420.251ZM365.854 420.91C365.854 420.546 366.149 420.251 366.513 420.251C366.877 420.251 367.172 420.546 367.172 420.91V435.395C367.172 435.758 366.877 436.053 366.513 436.053C366.149 436.053 365.854 435.758 365.854 435.395V420.91ZM374.424 420.251C374.06 420.251 373.764 420.546 373.764 420.91V435.395C373.764 435.758 374.06 436.053 374.424 436.053C374.788 436.053 375.083 435.758 375.083 435.395V420.91C375.083 420.546 374.788 420.251 374.424 420.251Z"
          }
          fill={"#b197fc"}></path>
        <path
          d={
            "M640.479 351.881C640.479 351.881 642.683 358.202 644.895 359.535C647.359 361.022 650.668 360.783 654.284 360.438C654.284 360.438 647.965 362.408 646.188 365.599C645.152 367.46 645.232 368.012 645.716 374.226C645.716 374.226 643.899 368.73 641.527 366.79C639.155 364.85 631.911 365.669 631.911 365.669C631.911 365.669 638.569 363.221 639.897 360.666C641.275 358.014 640.479 351.881 640.479 351.881Z"
          }
          fill={"#B5CDFB"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M303.106 316.43C307.955 315.488 312.65 318.65 313.593 323.492C317.468 343.391 326.306 374.691 348.637 406.323C351.483 410.355 350.518 415.928 346.481 418.77C342.444 421.613 336.864 420.649 334.018 416.617C309.763 382.26 300.208 348.332 296.035 326.904C295.092 322.061 298.258 317.372 303.106 316.43Z"
          }
          fill={"#FFCB9B"}></path>
        <path
          d={
            "M470.706 438.845C482.451 452.176 399.955 436.013 411.697 430.443C421.311 425.881 424.374 408.686 424.374 408.686L453.674 407.498C453.674 407.498 449.554 414.838 470.706 438.845Z"
          }
          fill={"#FFCB9B"}></path>
        <path
          d={
            "M455.736 329.86C457.546 330.219 468.668 314.483 469.975 316C476.68 323.786 485.053 392.322 480.907 397.606C476.761 402.889 460.175 399.462 460.175 399.462C460.175 399.462 451.424 412.306 461.973 424.601C454.244 423.833 433.323 428.46 424.446 410.463C422.385 408.112 411.722 399.998 415.907 377.269C416.479 374.158 419.007 362.246 428.962 352.232C435.349 345.808 442.135 342.839 447.962 340.29C455.911 336.812 451.401 329.001 455.736 329.86Z"
          }
          fill={"#FFCB9B"}></path>
        <path
          d={
            "M465.533 325.683L463.853 318.917L450.712 318.917L429.125 349.203L426.8 370.489L441.64 380.009C431.84 369.509 437.129 358.669 441.64 356.349C447.278 353.45 454.578 356.291 457.961 352.908C461.344 349.525 455.223 340.504 455.384 335.832C455.513 332.095 462.203 327.509 465.533 325.683Z"
          }
          fill={"black"}
          fillOpacity={"0.3"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M322.247 369.243C334.643 365.407 347.805 372.334 351.646 384.714C351.645 384.71 351.645 384.713 351.648 384.722C351.763 385.07 354.983 394.841 362.752 405.513C370.63 416.335 382.221 426.835 399.039 430.919C411.649 433.981 419.386 446.673 416.32 459.268C413.254 471.862 400.547 479.59 387.937 476.528C357.028 469.022 336.875 449.782 324.742 433.115C312.703 416.577 307.605 401.342 306.756 398.606C302.915 386.225 309.851 373.079 322.247 369.243Z"
          }
          fill={"#b197fc"}></path>
        <path
          d={
            "M516.852 569.506C497.977 579.736 430.68 580.11 407.294 566.937C406.898 564.276 405.52 558.777 403.637 551.66C403.185 549.967 402.696 548.181 402.159 546.3C400.417 540.217 398.214 533.212 395.532 525.718C394.826 523.752 387.227 498.504 383.036 487.529C371.715 457.884 373.652 437.278 385.48 426.236C409.738 429.455 418.897 421.78 418.897 421.78C418.897 421.78 426.241 430.722 435.128 430.431C440.475 430.252 448.825 431.145 461.572 423.529C481.69 439.608 494.496 435.169 504.952 446.132C512.848 454.411 510.319 472.875 513.143 494.82C513.36 496.475 513.557 498.14 513.764 499.823C514.508 506.188 515.148 512.704 515.657 519.22C515.789 520.932 515.92 522.634 516.033 524.335C516.485 530.936 516.815 537.499 516.993 543.855C517.04 545.557 517.078 547.25 517.106 548.914C517.229 556.211 517.154 563.159 516.852 569.506Z"
          }
          fill={"#b197fc"}></path>
        <path
          d={
            "M616.884 432.267C616.884 432.267 608.714 409.935 613.194 406.69C620.759 401.211 618.619 412.228 620.778 407.209C622.936 402.19 629.616 377.284 634.801 379.261C642.78 382.305 631.736 401.983 632.338 404.113C632.338 404.113 644.429 410.459 647.841 413.216C651.254 415.973 649.173 425.769 644.775 430.08C642.928 431.89 635.792 436.896 632.819 441.175C628.712 447.085 618.358 434.953 616.884 432.267Z"
          }
          fill={"#FFCB9B"}></path>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M626.489 417.183C631.428 417.283 635.349 421.363 635.249 426.295C634.955 440.739 629.629 459.4 621.919 474.707C619.699 479.115 614.323 480.89 609.91 478.673C605.498 476.456 603.72 471.086 605.94 466.679C612.774 453.111 617.139 437.075 617.366 425.932C617.466 420.999 621.551 417.082 626.489 417.183Z"
          }
          fill={"#FFCB9B"}></path>
        <path
          d={
            "M313.376 322.791C313.376 322.791 318.059 295.061 313.362 292.138C305.431 287.204 308.34 298.043 305.834 293.188C303.328 288.333 294.912 263.957 289.879 266.293C282.134 269.889 294.535 288.743 294.084 290.911C294.084 290.911 282.469 298.09 279.259 301.079C276.049 304.069 278.814 313.695 283.504 317.686C285.474 319.362 292.945 323.855 296.212 327.915C300.725 333.522 312.095 325.573 313.376 322.791Z"
          }
          fill={"#FFCB9B"}></path>
        <path
          d={
            "M392.778 337.156C385.947 326.35 392.778 320.232 395.132 318.821C397.485 317.411 404.813 316.951 425.728 302.837C441.616 292.115 450.676 259.584 450.676 259.584C450.676 259.584 491.792 311.569 474.682 328.694C469.515 333.865 466.627 337.656 461.963 340.88C460.17 342.118 469.563 355.37 464.797 358.783C460.669 361.738 452.655 359.197 450.205 362.073C447.277 365.511 443.955 373.494 446.374 379.468C448.793 385.442 457.736 383.7 460.09 389.811C462.443 395.923 450.578 403.855 447.851 408.147C444.853 412.866 442.345 403.513 434.671 410.497C427.321 417.187 420.265 412.956 419.609 412.848C413.407 411.826 418.257 397.796 406.823 385.442C399.895 377.957 394.682 380.418 388.251 371.476C384.657 366.479 382.267 362.223 383.757 351.551C385.247 340.88 390.895 337.156 392.778 337.156Z"
          }
          fill={"#B5CDFB"}></path>
        <path
          d={
            "M517.472 546.769C496.902 551.959 475.852 554.573 454.717 554.573C449.633 554.573 444.54 554.423 439.438 554.122C427.5 553.417 414.619 551.527 402.955 549.176C402.503 547.483 402.013 545.697 401.477 543.816C413.706 546.412 427.19 548.433 439.739 549.176C465.798 550.727 491.837 548.226 517.133 541.748C517.208 541.729 517.284 541.71 517.359 541.71C517.406 543.412 517.444 545.105 517.472 546.769Z"
          }
          fill={"white"}></path>
        <path
          d={
            "M516.399 522.19C496.385 529.224 475.024 532.872 453.879 532.872C451.366 532.872 448.871 532.815 446.367 532.712C429.271 532.007 410.482 528.076 394.148 522.744C393.442 520.779 393.098 518.397 392.326 516.375C409.357 522.346 428.612 527.033 446.574 527.776C469.968 528.735 493.871 525.039 516.023 517.075C516.154 518.787 516.286 520.488 516.399 522.19Z"
          }
          fill={"white"}></path>
        <path
          d={
            "M514.13 497.678C495.876 503.366 476.831 506.272 457.834 506.272C451.319 506.272 444.814 505.933 438.355 505.247C418.539 503.14 402.382 501.049 384.279 492.869C383.008 490.396 382.895 487.335 381.521 484.9C400.914 494.509 417.39 498.035 438.873 500.32C463.83 502.981 489.399 500.329 513.509 492.675C513.726 494.33 513.923 495.994 514.13 497.678Z"
          }
          fill={"white"}></path>
        <path
          d={
            "M510.225 451.258C513.298 454.834 516.43 458.405 519.978 461.432L521.281 462.605L521.932 463.193L522.619 463.723L525.358 465.866L526.04 466.408L526.753 466.889L528.181 467.853L529.604 468.828L530.314 469.32L531.052 469.745L533.997 471.469C534.48 471.78 534.985 472.027 535.492 472.265L537.003 473.009C538.995 474.104 541.078 474.8 543.119 475.695C547.257 477.125 551.441 478.275 555.585 478.804C559.714 479.263 563.78 479.3 567.683 478.801C569.575 478.392 571.539 478.164 573.338 477.516L574.728 477.126C575.199 477.012 575.625 476.792 576.079 476.637C576.99 476.333 577.849 475.892 578.742 475.521C580.478 474.662 582.203 473.722 583.871 472.599C585.532 471.465 587.16 470.208 588.735 468.813C591.874 466.005 594.784 462.645 597.365 458.911L597.561 458.627C603.764 449.652 616.078 447.398 625.065 453.594C633.731 459.569 636.108 471.269 630.705 480.141C626.852 486.439 622.266 492.455 616.814 497.923C614.079 500.65 611.132 503.232 607.992 505.635C604.841 508.031 601.459 510.194 597.943 512.158C596.152 513.086 594.376 514.049 592.512 514.847C591.583 515.248 590.673 515.706 589.722 516.046L586.874 517.088C583.087 518.577 579.17 519.468 575.255 520.403C567.386 521.937 559.454 522.318 551.692 521.843C543.936 521.296 536.448 519.724 529.205 517.596C525.672 516.372 522.06 515.243 518.692 513.698L516.129 512.595C515.271 512.232 514.42 511.856 513.609 511.423L508.683 508.894L507.455 508.254L506.274 507.551L503.917 506.136L501.563 504.71L500.39 503.99L499.266 503.214L494.779 500.083L493.66 499.294L492.593 498.451L490.465 496.756C484.714 492.294 479.601 487.196 474.668 481.949C465.78 472.496 466.248 457.636 475.713 448.76C485.178 439.883 500.056 440.35 508.943 449.804C509.162 450.036 509.376 450.273 509.583 450.512L510.225 451.258Z"
          }
          fill={"#b197fc"}></path>
      </svg>
    </>
  );
}
