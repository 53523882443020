import * as Xstate from "@xstate/react";
import React from "react";
import { useEffectOnce } from "usehooks-ts";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { PageLayout } from "../../components/layouts/PageLayout";
import { GetPageTitle } from "../../support/ScrollToTop";
import { HomeBankDocsListView } from "./HomeBankDocsListView";
import { HomeQuickStartView } from "./HomeQuickStartView";

export const HomeView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Home");
  });

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const fullName = userInfoByEmail?.userProfile?.fullName ?? "";

  return (
    <PageLayout title={`Welcome, ${fullName}!`}>
      <HomeQuickStartView />
      <HomeBankDocsListView />
    </PageLayout>
  );
};
