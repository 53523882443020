import { useMutation } from "@apollo/client";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";
import * as Xstate from "@xstate/react";
import { List } from "baseui/dnd-list";
import _ from "lodash";
import React from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { BW_STYLES } from "../../app/constants/CssConstants";
import { LABELS } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { Alert } from "../../components/alerts/alert";
import { BrandButton } from "../../components/button/BrandButton";
import { OutlineButton } from "../../components/button/OutlineButton";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  EditContactEmailInput,
  EditContactPhoneNumberInput,
  EditCustomer,
  EditCustomerAddressInput,
  EditCustomerInput,
  EditCustomerTaxIdInput,
  EditCustomerVariables,
  GetCustomerById_customerById,
} from "../../generated/operation-result-types";
import {
  EDIT_CUSTOMER_GQL,
  GET_CUSTOMER_BY_ID_GQL,
} from "../../queries/CustomerQueries.gql";
import { FormSection } from "../../support/FormSection";

type Props = { customerById: GetCustomerById_customerById };

export const EditCustomerView: React.FC<Props> = ({ customerById }) => {
  const navigate = useNavigate();

  // current location
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation, userInfoByEmail } = userInfoState.context;

  // field defaults
  const addressDefaultValues: EditCustomerAddressInput = {
    line1: "",
    city: "",
    country: "",
    postalCode: "",
    region: "",
    addressTypeEnumId:
      "QWRkcmVzc1R5cGVFbnVtCmcxMmY3M2M3Mzg5ZTU0ZjY5OWYxNGVkN2ExZmZjMzA4MA==",
  };
  const phoneDefaultValues: EditContactPhoneNumberInput = {
    number: "",
    phoneNumberTypeEnumId:
      "UGhvbmVOdW1iZXJUeXBlRW51bQpnYmNkNjk3YThlZjRjNGY5MWJiOGY4OTFkZjFkZGZiYTA=",
  };
  const customerTaxIdDefaultValues: EditCustomerTaxIdInput = {
    taxIdType: "",
    taxIdValue: "",
  };

  const editCustomerTaxIdInputs = customerById.customerTaxIds.map(
    (x): EditCustomerTaxIdInput => {
      const y = _.omit(x, ["__typename"]);
      return {
        ...y,
      };
    },
  );
  const editCustomerAddressInputs = customerById.addresses.map(
    (x): EditCustomerAddressInput => {
      const y = _.omit(x, ["__typename", "addressTypeEnum"]);
      return {
        ...y,
        addressTypeEnumId: x.addressTypeEnum.id,
      };
    },
  );
  const editContactPhoneNumberInputs = customerById.contact?.phoneNumbers.map(
    (x): EditContactPhoneNumberInput => {
      const y = _.omit(x, ["__typename", "phoneNumberTypeEnum"]);
      return {
        ...y,
        phoneNumberTypeEnumId: x.phoneNumberTypeEnum.id,
      };
    },
  );
  const editContactEmailInput: EditContactEmailInput = _.omit(
    customerById.contact?.email,
    ["__typename"],
  );

  // RHF
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<EditCustomerInput>({
    defaultValues: {
      orgId: userInfoByEmail?.org?.id,
      locationId: currentLocation?.id,
      customerId: customerById.id,
      customerName: customerById.name,
      editCustomerTaxIdInputs: editCustomerTaxIdInputs,
      editCustomerAddressInputs: editCustomerAddressInputs,
      editContactInput: {
        id: customerById.contact?.id,
        firstName: customerById.contact?.firstName,
        middleName: customerById.contact?.middleName,
        lastName: customerById.contact?.lastName,
        editContactPhoneNumberInputs: editContactPhoneNumberInputs,
        editContactEmailInput: editContactEmailInput,
      },
    },
  });
  const {
    fields: phonesFields,
    append: phonesAppend,
    remove: phonesRemove,
    move: phonesMove,
  } = useFieldArray({
    control,
    name: "editContactInput.editContactPhoneNumberInputs",
  });
  const phonesAppendWithDefaults = () => {
    phonesAppend({
      ...phoneDefaultValues,
    });
  };
  const {
    fields: addressesFields,
    append: addressesAppend,
    remove: addressesRemove,
    move: addressesMove,
  } = useFieldArray({
    control,
    name: "editCustomerAddressInputs",
  });
  const addressesAppendWithDefaults = () => {
    addressesAppend({
      ...addressDefaultValues,
    });
  };
  const {
    fields: taxIdFields,
    append: taxIdAppend,
    remove: taxIdRemove,
    move: taxIdMove,
  } = useFieldArray({
    control,
    name: "editCustomerTaxIdInputs",
  });
  const taxIdAppendWithDefaults = () => {
    taxIdAppend(customerTaxIdDefaultValues);
  };

  // refetch queries
  const refetchQueries: InternalRefetchQueryDescriptor[] = [
    {
      query: GET_CUSTOMER_BY_ID_GQL,
      variables: { customerId: customerById.id },
    },
  ];

  // mutation
  const [updateCustomer, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<EditCustomer, EditCustomerVariables>(EDIT_CUSTOMER_GQL, {
      refetchQueries: refetchQueries,
    });

  // local effects
  React.useEffect(() => {
    setFocus("customerName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RHF submit handler
  const onSubmit: SubmitHandler<EditCustomerInput> = async (data) => {
    resetM();
    await updateCustomer({
      variables: {
        editCustomerInput: data,
      },
    });
    if (!errorM) {
      navigate("../");
    }
  };

  const { nameRule, profileRules, phoneNumberRule, addressRule, taxIdRule } =
    ValidationConstants.customerRules;

  const addressesRows = addressesFields.map((address, index) => {
    return (
      <div
        key={address.id}
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 1 {LABELS.optional}</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerAddressInputs.${index}.line1`, {
              required: false,
              ...addressRule.line1Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerAddressInputs?.[index]?.line1?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerAddressInputs?.[index]?.line1?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 2 {LABELS.optional}</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerAddressInputs.${index}.line2`, {
              required: false,
              ...addressRule.line2Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerAddressInputs?.[index]?.line2?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerAddressInputs?.[index]?.line2?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>City</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerAddressInputs.${index}.city`, {
              ...addressRule.cityLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerAddressInputs?.[index]?.city?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerAddressInputs?.[index]?.city?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Province / State</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerAddressInputs.${index}.region`, {
              ...addressRule.regionLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerAddressInputs?.[index]?.region?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerAddressInputs?.[index]?.region?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Country</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerAddressInputs.${index}.country`, {
              required: LABELS.required,
              ...addressRule.countryLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerAddressInputs?.[index]?.country?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerAddressInputs?.[index]?.country?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Postal / Zip Code</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerAddressInputs.${index}.postalCode`, {
              ...addressRule.postalCodeLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerAddressInputs?.[index]?.postalCode?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerAddressInputs?.[index]?.postalCode?.message}
            </span>
          )}
        </div>
        <BrandButton
          colorType={"link"}
          label={"Remove address"}
          onClick={() => addressesRemove(index)}
        />
      </div>
    );
  });

  const phoneNumberRows = phonesFields.map((phoneNumber, index) => {
    return (
      <div
        key={phoneNumber.id}
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Phone number</span>
          </label>
          <input
            type={"text"}
            {...register(
              `editContactInput.editContactPhoneNumberInputs.${index}.number`,
              {
                required: LABELS.required,
                ...phoneNumberRule.valueLength,
              },
            )}
            className={"input input-bordered"}
          />
          {errors?.editContactInput?.editContactPhoneNumberInputs?.[index]
            ?.number?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {
                errors?.editContactInput?.editContactPhoneNumberInputs?.[index]
                  ?.number?.message
              }
            </span>
          )}
        </div>

        <BrandButton
          colorType={"link"}
          label={"Remove phone"}
          onClick={() => phonesRemove(index)}
        />
      </div>
    );
  });

  const taxIdRows = taxIdFields.map((taxIdField, index) => {
    return (
      <div
        key={taxIdField.id}
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <div className={"label-text"}>Identification Name</div>
          </label>
          <input
            type={"text"}
            {...register(
              `editCustomerTaxIdInputs.${index}.taxIdType` as const,
              {
                required: LABELS.required,
                ...taxIdRule.nameLength,
              },
            )}
            placeholder={"Example: Business number, GST, SIN etc."}
            className={"input input-bordered"}
          />
          {errors?.editCustomerTaxIdInputs?.[index]?.taxIdType?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerTaxIdInputs?.[index]?.taxIdType?.message}
            </span>
          )}
        </div>

        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Identification Number</span>
          </label>
          <input
            type={"text"}
            {...register(`editCustomerTaxIdInputs.${index}.taxIdValue`, {
              required: LABELS.required,
              ...taxIdRule.valueLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.editCustomerTaxIdInputs?.[index]?.taxIdValue?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.editCustomerTaxIdInputs?.[index]?.taxIdValue?.message}
            </span>
          )}
        </div>

        <BrandButton
          colorType={"link"}
          label={"Remove identification"}
          onClick={() => taxIdRemove(index)}
        />
      </div>
    );
  });

  const emailRows = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
      }>
      <div className={"form-control"}>
        <label className={"label"}>
          <span className={"label-text"}>Email *</span>
        </label>
        <input
          type={"text"}
          {...register("editContactInput.editContactEmailInput.emailAddress", {
            required: LABELS.required,
            ...profileRules.emailLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.editContactInput?.editContactEmailInput?.emailAddress
          ?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {
              errors?.editContactInput?.editContactEmailInput?.emailAddress
                ?.message
            }
          </span>
        )}
      </div>
    </div>
  );

  const customerInfoRow = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-5"
      }>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Customer / Business name *</span>
        </label>
        <input
          type={"text"}
          {...register("customerName", {
            required: LABELS.required,
            ...nameRule.valueLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.customerName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.customerName?.message}
          </span>
        )}
      </div>
      <div className={"form-control lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>First name</span>
        </label>
        <input
          type={"text"}
          {...register("editContactInput.firstName", {
            required: false,
            ...profileRules.firstNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.editContactInput?.firstName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.editContactInput?.firstName?.message}
          </span>
        )}
      </div>

      <div className={"form-control lg:col-span-1"}>
        <label className={"label"}>
          <span className={"label-text"}>Initials</span>
        </label>
        <input
          type={"text"}
          {...register("editContactInput.middleName", {
            required: false,
            ...profileRules.middleNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.editContactInput?.middleName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.editContactInput?.middleName?.message}
          </span>
        )}
      </div>

      <div className={"form-control lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Last name</span>
        </label>
        <input
          type={"text"}
          {...register("editContactInput.lastName", {
            required: false,
            ...profileRules.lastNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.editContactInput?.lastName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.editContactInput?.lastName?.message}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <>
      {/*<DevTool control={control} placement={"bottom-right"} />*/}
      <div className={"pb-4 text-xl font-semibold"}>Edit Customer</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"Customer Information"}>
          {customerInfoRow}
        </FormSection>

        <FormSection name={"Email addresses"}>{emailRows}</FormSection>

        <FormSection name={"Phone numbers"}>
          <List
            items={phoneNumberRows}
            overrides={BW_STYLES.listOverrides}
            onChange={({ oldIndex, newIndex }) => {
              phonesMove(oldIndex, newIndex);
            }}
          />

          <BrandButton
            colorType={"link"}
            label={"Add phone number"}
            onClick={phonesAppendWithDefaults}
          />
        </FormSection>

        <FormSection name={"Addresses"}>
          <List
            items={addressesRows}
            overrides={BW_STYLES.listOverrides}
            onChange={({ oldIndex, newIndex }) => {
              addressesMove(oldIndex, newIndex);
            }}
          />

          <BrandButton
            colorType={"link"}
            label={"Add address"}
            onClick={addressesAppendWithDefaults}
          />
        </FormSection>

        <FormSection
          name={"Identification"}
          description={"Add a business number, GST/HST number etc."}>
          <List
            items={taxIdRows}
            overrides={BW_STYLES.listOverrides}
            onChange={({ oldIndex, newIndex }) => {
              taxIdMove(oldIndex, newIndex);
            }}
          />

          <BrandButton
            colorType={"link"}
            label={"Add identification"}
            onClick={taxIdAppendWithDefaults}
          />
        </FormSection>

        {errorM && <Alert type={"error"} label={LABELS.errors.default} />}

        <div className={"flex justify-start space-x-4 pb-4"}>
          <BrandButton
            buttonType={"submit"}
            colorType={"primary"}
            label={"Update Customer"}
            SvgIconLeft={AddStackSvg}
            disabled={loadingM}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
      </form>
    </>
  );
};
