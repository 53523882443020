import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import { AxiosRequestConfig } from "axios";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { protectedResources } from "../../../app/configs/appConfig";
import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { AlertSimple } from "../../../components/alerts/AlertSimple";
import { BrandButton } from "../../../components/button/BrandButton";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { FileUploaderView } from "../../../components/fileUploader/FileUploaderView";
import { AddStackSvg } from "../../../components/svg/AddStackSvg";
import {
  GetCustomerById_customerById,
  GetCustomersByLocationForAdd_customers_edges,
} from "../../../generated/operation-result-types";
import { postApiWithToken } from "../../../support/FetchWithToken";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

type BankDocRestApiInput = {
  name: string;
  customer: {
    id: string;
  };
  docType: "CREDIT_CARD" | "BANK_STATEMENT";
  selectedFile: File;
};

type Props = {
  customer?: GetCustomerById_customerById;
  customers?: GetCustomersByLocationForAdd_customers_edges[] | null;
};

export const AddBankDocView: React.FC<Props> = (props) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Add Bank Statement");
  });

  const { customer, customers } = props;
  const navigate = useNavigate();

  // app insights
  const appInsights = useAppInsightsContext();
  const trackBankDocAdded = useTrackEvent(
    appInsights,
    "Bank statement uploaded",
    {},
  );

  // local state
  const [isRestApiLoading, setIsRestApiLoading] =
    React.useState<boolean>(false);
  const [progressAmount, setProgressAmount] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  // RHF
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<BankDocRestApiInput>({
    defaultValues: {
      customer: { id: customer?.id },
    },
  });

  // local effects
  React.useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  // RHF submit handler
  const onSubmit: SubmitHandler<BankDocRestApiInput> = async (data) => {
    if (!userInfoByEmail) return;

    const formData = new FormData();
    formData.append("formFile", data.selectedFile, data.selectedFile.name);

    const axiosRequestConfig: AxiosRequestConfig = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setProgressAmount(percentCompleted);
      },
      params: {
        customerId: data.customer.id,
        fileName: data.name,
        uploadedByUserId: userInfoByEmail.id,
        isCreditCard: data.docType === "CREDIT_CARD",
      },
      headers: {},
    };

    setIsRestApiLoading(true);
    try {
      const response = await postApiWithToken(
        protectedResources.bankDocs.uploadEndpoint,
        formData,
        axiosRequestConfig,
      );
      if (response.status === 200) {
        trackBankDocAdded({
          fileName: data.name,
          fileType: data.docType,
          customerId: data.customer.id,
        });
        navigate("../");
      } else {
        setErrorMessage(response.data);
      }
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.toString());
      }
    }
    setIsRestApiLoading(false);
  };

  // customer dropdown view rows
  const customersDropdownView = customers?.map((c) => {
    const { id, name } = c.node;
    return (
      <option key={id} value={id}>
        {name}
      </option>
    );
  });
  const singleCustomerFormControl = (
    <div className={"flex flex-col items-start"}>
      <div>{customer?.name}</div>
    </div>
  );
  const multipleCustomerFormControl = (
    <select
      {...register("customer.id", {
        required: LABELS.required,
      })}
      className={"select select-bordered w-full"}>
      {customersDropdownView}
    </select>
  );

  const customerFormControl = (
    <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
      <label className={"label"}>
        <span className={"label-text"}>{LABELS.features.customer} Name</span>
      </label>
      <div className={"w-full"}>
        {customer ? singleCustomerFormControl : multipleCustomerFormControl}
        {errors?.customer?.id?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.customer?.id?.message}
          </span>
        )}
      </div>
    </div>
  );

  const uploadSection = (
    <FormSection name={"Upload Bank Statement"}>
      <AlertSimple type={"info"} label={"File requirements"}>
        <div>
          <li>
            Only 1 statement per upload. Do not combine multiple statements.
          </li>
          <li>PDF file format (scanned or digital)</li>
          <li>Maximum A4 or letter size</li>
          <li>Minimum 200dpi scan resolution</li>
          <li>Maximum 25MB file size</li>
          <li>Maximum 20 pages</li>
        </div>
      </AlertSimple>

      {customerFormControl}

      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>File Name</span>
        </label>
        <div className={"w-full"}>
          <input
            type={"text"}
            {...register("name", {
              required: LABELS.required,
              ...ValidationConstants.bankDocAddEditRules.nameRule.valueLength,
            })}
            className={"input input-bordered w-full"}
          />
          {errors?.name?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.name?.message}
            </span>
          )}
        </div>
      </div>

      <div className={"form-control grid grid-cols-1 lg:grid-cols-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Document Type</span>
        </label>
        <div className={"w-full"}>
          <Controller
            control={control}
            name={"docType"}
            rules={{ required: LABELS.required }}
            render={({ field: { onChange } }) => {
              const bankStatementValue = "bank-statement";
              const creditCardStatementValue = "credit-card-statement";
              return (
                <div className={"flex flex-row space-x-4"}>
                  <label className={"label cursor-pointer space-x-2"}>
                    <input
                      type={"radio"}
                      name={"document-type"}
                      className={"radio radio-primary"}
                      value={bankStatementValue}
                      onChange={() => onChange("BANK_STATEMENT")}
                    />
                    <span className={"label-text"}>Bank Statement</span>
                  </label>
                  <label className={"label cursor-pointer space-x-2"}>
                    <input
                      type={"radio"}
                      name={"document-type"}
                      className={"radio radio-primary"}
                      value={creditCardStatementValue}
                      onChange={() => onChange("CREDIT_CARD")}
                    />
                    <span className={"label-text"}>Credit Card Statement</span>
                  </label>
                </div>
              );
            }}
          />
          {errors?.docType?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.docType?.message}
            </span>
          )}
        </div>
      </div>

      <div className={"form-control grid grid-cols-1"}>
        <Controller
          control={control}
          rules={{ required: LABELS.required }}
          name={"selectedFile"}
          render={({ field: { onChange } }) => {
            return (
              <FileUploaderView
                onFileSelected={(validFile) => onChange(validFile)}
                progressAmount={progressAmount}
              />
            );
          }}
        />
        {errors?.selectedFile?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.selectedFile?.message}
          </span>
        )}
      </div>
    </FormSection>
  );

  return (
    <>
      {/*<DevTool control={control} placement={"bottom-right"} />*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isRestApiLoading}>
          <>{uploadSection}</>
        </fieldset>

        {errorMessage && <Alert type={"error"} label={LABELS.errors.default} />}

        <div className={"flex justify-start space-x-4 pb-4"}>
          <BrandButton
            buttonType={"submit"}
            colorType={"primary"}
            label={"Upload"}
            disabled={isRestApiLoading}
            SvgIconLeft={AddStackSvg}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
      </form>
    </>
  );
};
