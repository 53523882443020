import * as Apollo from "@apollo/client";
import React from "react";

import { NOW_UTC } from "../../app/commonOps/CommonDateOps";
import { LABELS } from "../../app/constants/TextConstants";
import { AlertSimple } from "../../components/alerts/AlertSimple";
import {
  BarChartSf01,
  ChartDataPoint,
} from "../../components/charts/BarChartSf01";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  GetBankDocsAggByDate,
  GetBankDocsAggByDateVariables,
} from "../../generated/operation-result-types";
import { GET_BANK_DOCS_AGG_BY_DATE } from "../../queries/HomeQueries.gql";

type Props = {
  locationId: string;
};

export const HomeBankDocsAggChartView: React.FC<Props> = ({ locationId }) => {
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetBankDocsAggByDate, GetBankDocsAggByDateVariables>(
    GET_BANK_DOCS_AGG_BY_DATE,
    {
      fetchPolicy: "network-only",
      variables: {
        locationId: locationId,
        currentDt: NOW_UTC(),
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("HomeJobsDueListView | HomeBankDocsChartView", { errorQ });
    throw new Error("Error getting HomeBankDocsAggChartView");
  }

  const chartDataPoints = dataQ?.bankDocsAggByDate.rows.map(
    (row): ChartDataPoint => {
      return {
        eventDate: row.eventDate,
        eventCount: row.eventCount,
      };
    },
  );
  if (!chartDataPoints || chartDataPoints?.length === 0) {
    return (
      <AlertSimple label={"Empty!"} type={"info"}>
        {LABELS.empty.noData}
      </AlertSimple>
    );
  }

  return (
    <BarChartSf01
      chartName={"Recent Bank Documents"}
      dataPoints={chartDataPoints}
    />
  );
};
