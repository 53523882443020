import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import React from "react";

import { protectedResources } from "../../app/configs/appConfig";
import { setAuthToken } from "../../app/globalState/GlobalAuthState";
import { getApiWithToken } from "../../support/FetchWithToken";
import { HomeView } from "./HomeView";

export const HomeAuthView: React.FC<unknown> = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [helloData, setHelloData] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (account && inProgress === "none" && !helloData) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account,
        })
        .then((response) => {
          setAuthToken(response.accessToken);
          getApiWithToken(protectedResources.apiHello.endpoint).then((x) => {
            setHelloData(x.data.toString());
          });
        })
        .catch((error) => {
          // todo: this happens when user is logged in and token expires after X days
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              instance.acquireTokenRedirect({
                scopes: protectedResources.apiHello.scopes,
              });
              // .then((response) => {
              //   setAuthToken(response.accessToken);
              //   getApiWithToken(protectedResources.apiHello.endpoint).then(
              //     (x) => setHelloData(x.data.toString()),
              //   );
              // })
            }
          }
        });
    }
  }, [account, helloData, inProgress, instance]);

  return <HomeView />;
};
