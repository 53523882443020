import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import { PATHS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AlertSimple } from "../../components/alerts/AlertSimple";
import { BrandButton } from "../../components/button/BrandButton";
import { ArrowChevronRightSvg } from "../../components/svg/ArrowChevronRightSvg";
import {
  GetOrgBillingPlanLimits,
  GetOrgBillingPlanLimitsVariables,
} from "../../generated/operation-result-types";
import { GET_ORG_BILLING_PLAN_LIMITS_GQL } from "../../queries/OrgBillingQueries.gql";

type Props = { forceShowChildren?: boolean };

export const PlanLimitsBannerView: React.FC<Props> = ({
  forceShowChildren = false,
  children,
}) => {
  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<
    GetOrgBillingPlanLimits,
    GetOrgBillingPlanLimitsVariables
  >(GET_ORG_BILLING_PLAN_LIMITS_GQL, {
    fetchPolicy: "network-only",
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orgId: userInfoByEmail!.org!.id,
    },
  });

  if (errorQ) {
    throw new Error("Error getting BankDocsFreeTrialLimitBannerView");
  }

  if (loadingQ) {
    return null;
  }

  const orgBilling = _.first(dataQ?.orgBillings?.edges)?.node;
  if (!orgBilling) {
    throw new Error("Missing billing info");
  }

  const { hasExceededBdPlanLimit, bankDocsIsFreeTrialPlan } = orgBilling;

  const addMoreLicensesView = (
    <AlertSimple type={"warning"}>
      <div className={"flex items-center space-x-4"}>
        <div>You are out of licenses! Please add more licenses.</div>
        <div>
          <BrandButton
            colorType={"warning"}
            label={"View licenses"}
            size={"small"}
            SvgIconRight={ArrowChevronRightSvg}
            onClick={() => navigate(PATHS.settingsBilling())}
          />
        </div>
      </div>
    </AlertSimple>
  );

  const upgradeToPaidView = (
    <AlertSimple type={"warning"}>
      <div className={"flex items-center space-x-4"}>
        <div>You are on a free trial. Upgrade to a paid plan now!</div>
        <div>
          <BrandButton
            colorType={"primary"}
            label={"View plans"}
            size={"small"}
            SvgIconRight={ArrowChevronRightSvg}
            onClick={() => navigate(PATHS.settingsBilling())}
          />
        </div>
      </div>
    </AlertSimple>
  );

  return (
    <div className={"flex justify-between pb-4"}>
      <div>
        {hasExceededBdPlanLimit
          ? addMoreLicensesView
          : bankDocsIsFreeTrialPlan && upgradeToPaidView}
      </div>

      <div>
        {forceShowChildren
          ? children
          : hasExceededBdPlanLimit
          ? null
          : children}
      </div>
    </div>
  );
};
