import { gql } from "@apollo/client";

export const GET_BANK_DOCS_AGG_BY_DATE = gql`
  query GetBankDocsAggByDate($locationId: ID!, $currentDt: DateTime!) {
    bankDocsAggByDate(locationId: $locationId, currentDt: $currentDt) {
      rows {
        eventDate
        eventCount
      }
    }
  }
`;

export const GET_JOBS_BY_USER_AND_DUE_DATE_GQL = gql`
  query GetJobsByUserAndDueDate($userId: ID!, $fromDueDate: DateTime!) {
    jobs(
      where: {
        dueDate: { gte: $fromDueDate }
        assignedUser: { id: { eq: $userId } }
      }
      first: 20
    ) {
      edges {
        node {
          id
          name
          description
          dueDate

          customer {
            id
            name
          }

          jobStatus {
            id
            name
            color
          }

          assignedUser {
            id
            userProfile {
              id
              fullName
              avatarInitials
            }
          }
        }
      }
    }
  }
`;
