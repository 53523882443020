import React from "react";

import { GetCustomerById_customerById } from "../../../generated/operation-result-types";
import { AddBankDocView } from "./AddBankDocView";

type AddJobForCustomer = {
  customerById: GetCustomerById_customerById;
};

export const AddBankDocForCustomerView: React.FC<AddJobForCustomer> = (
  props,
) => {
  const { customerById } = props;

  return (
    <>
      <AddBankDocView customer={customerById} />
    </>
  );
};
