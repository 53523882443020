import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { LABELS } from "../../app/constants/TextConstants";
import { PageLayout } from "../../components/layouts/PageLayout";
import { CustomLink } from "../../support/CustomLink";
import { BillingView } from "./billing/BillingView";
import { AddBdLicensesView } from "./licenses/AddBdLicensesView";
import { AddLocationView } from "./locations/AddLocationView";
import { EditLocationView } from "./locations/EditLocationView";
import { LocationsListView } from "./locations/LocationsListView";
import { EditOrgDetails } from "./OrgDetails/EditOrgDetails";
import { OrgDetailsView } from "./OrgDetails/OrgDetailsView";
import { SubscriptionPlansView } from "./subscriptions/SubscriptionPlansView";
import { AddUserView } from "./users/AddUserView";
import { EditUserView } from "./users/EditUserView";
import { UsersListView } from "./users/UsersListView";

export const SettingsView: React.FC<unknown> = () => {
  return (
    <PageLayout title={LABELS.features.settings}>
      <div className={"tabs my-8"}>
        <CustomLink to={"company"}>Company</CustomLink>
        <CustomLink to={"locations"}>Locations</CustomLink>
        <CustomLink to={"users"}>Users</CustomLink>
        <CustomLink to={"billing"}>Billing</CustomLink>
        <CustomLink to={"plans"}>Plans</CustomLink>
      </div>

      <Routes>
        <Route index element={<Navigate to={"./company"} replace={true} />} />
        <Route path={"company"} element={<Outlet />}>
          <Route index element={<OrgDetailsView />} />
          <Route path={"edit"} element={<EditOrgDetails />} />
        </Route>
        <Route path={"locations"} element={<Outlet />}>
          <Route index element={<LocationsListView />} />
          <Route path={":locationId/edit"} element={<EditLocationView />} />
          <Route path={"add"} element={<AddLocationView />} />
        </Route>
        <Route path={"users"} element={<Outlet />}>
          <Route index element={<UsersListView />} />
          <Route path={"add"} element={<AddUserView />} />
          <Route path={":userId/edit"} element={<EditUserView />} />
        </Route>
        <Route path={"billing"} element={<Outlet />}>
          <Route index element={<BillingView />} />
        </Route>
        <Route path={"licenses"} element={<Outlet />}>
          <Route index element={<AddBdLicensesView />} />
        </Route>
        <Route path={"plans"} element={<Outlet />}>
          <Route index element={<SubscriptionPlansView />} />
        </Route>
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
