import React from "react";

import { LABELS } from "../../app/constants/TextConstants";
import { AlertSimple } from "../alerts/AlertSimple";

export const EmptyDataView: React.FC<unknown> = () => {
  return (
    <AlertSimple label={"Empty!"} type={"info"}>
      {LABELS.empty.noData}
    </AlertSimple>
  );
};
