import { gql } from "@apollo/client";

export const GET_ORG_BILLING_PLAN_LIMITS_GQL = gql`
  query GetOrgBillingPlanLimits($orgId: ID!) {
    orgBillings(where: { org: { id: { eq: $orgId } } }) {
      edges {
        node {
          bankDocsIsFreeTrialPlan
          hasExceededBdPlanLimit
          totalUsageSavings {
            totalPageCount
            totalTimeSaved
          }
        }
      }
    }
  }
`;

export const GET_ORG_BILLING_GQL = gql`
  query GetOrgBilling($orgId: ID!) {
    orgBillings(where: { org: { id: { eq: $orgId } } }) {
      edges {
        node {
          id
          createdAt
          subscriptionPlan {
            id
            isCurrent
            name
            primaryAmount
            primaryDuration
            primaryUnit
            currency
            additionalAmount
            additionalUnit
          }
          paymentInfo {
            procCardInfo {
              cardLast4
              cardBrand
            }
          }
          totalUsageSavings {
            totalPageCount
            totalTimeSaved
          }
          billingCycleUsage {
            upcomingInvoiceInfo {
              startDate
              endDate
              amount
              currency
            }
            totalBdCustomersPurchased
            totalBdCustomersUsed
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PLANS_GQL = gql`
  query GetSubscriptionPlans($orgId: ID!) {
    orgBillings(where: { org: { id: { eq: $orgId } } }) {
      edges {
        node {
          id
          subscriptionPlans {
            id
            isCurrent
            canChoose
            name
            primaryAmount
            primaryDuration
            primaryUnit
            currency
            additionalAmount
            additionalUnit
            features
            flairText
          }
          paymentInfo {
            procCardInfo {
              cardLast4
              cardBrand
            }
            setupIntentId
            clientPublishableKey
          }
        }
      }
    }
  }
`;

export const SWITCH_BD_PLAN_GQL = gql`
  mutation SwitchBdSubscriptionPlan(
    $switchBdSubscriptionInput: SwitchBdSubscriptionInput!
  ) {
    switchBdSubscription(input: $switchBdSubscriptionInput) {
      switchBdSubscriptionOutput {
        newSubscriptionPlanId
      }
    }
  }
`;

export const ADD_BD_LICENSES_GQL = gql`
  mutation AddBdLicenses($addBdLicensesInput: AddBdLicensesInput!) {
    addBdLicenses(input: $addBdLicensesInput) {
      orgBilling {
        id
      }
    }
  }
`;
