import React from "react";
import { match } from "ts-pattern";

import { TW_SIZES } from "../../app/constants/CssConstants";
import { SvgIconProps } from "../svg/SvgIconProps";

interface Props {
  colorType?: "info" | "success" | "warning" | "error";
  style?: "filled" | "outline";
  label: string;
  size?: "small" | "medium" | "large" | "extra-small";
  SvgIconLeft?: React.FunctionComponent<SvgIconProps>;
  SvgIconRight?: React.FunctionComponent<SvgIconProps>;
}

export const Badge: React.FC<Props> = (props) => {
  const {
    colorType = "info",
    style = "outline",
    label,
    size = "medium",
    SvgIconLeft,
    SvgIconRight,
  } = props;

  const badgeActiveClass: string = match(colorType)
    .with("info", () => "badge-info")
    .with("success", () => "badge-success")
    .with("warning", () => "badge-warning")
    .with("error", () => "badge-error")
    .exhaustive();

  const styleActiveClass: string = match(style)
    .with("filled", () => "")
    .with("outline", () => "badge-outline")
    .exhaustive();

  const badgeSizeClass: string = match(size)
    .with("large", () => "badge-lg")
    .with("small", () => "badge-sm")
    .with("extra-small", () => "badge-xs")
    .with("medium", () => "badge-md")
    .exhaustive();

  const svgIconMarginClass = match(size)
    .with("extra-small", () => (SvgIconLeft ? TW_SIZES.mr.xs : TW_SIZES.ml.xs))
    .with("small", () => (SvgIconLeft ? TW_SIZES.mr.sm : TW_SIZES.ml.sm))
    .with("medium", () => (SvgIconLeft ? TW_SIZES.mr.md : TW_SIZES.ml.md))
    .with("large", () => (SvgIconLeft ? TW_SIZES.mr.lg : TW_SIZES.ml.lg))
    .exhaustive();

  const svgIconSizeClass = match(size)
    .with("extra-small", () => TW_SIZES.hw.xs)
    .with("small", () => TW_SIZES.hw.sm)
    .with("medium", () => TW_SIZES.hw.md)
    .with("large", () => TW_SIZES.hw.lg)
    .exhaustive();

  const svgIconClassNames = `${svgIconSizeClass} ${svgIconMarginClass}`;

  return (
    <>
      <div
        className={`badge ${badgeSizeClass} ${badgeActiveClass} ${styleActiveClass} cursor-default`}>
        {SvgIconLeft && <SvgIconLeft sizeCx={svgIconClassNames} />}
        {label}
        {SvgIconRight && <SvgIconRight sizeCx={svgIconClassNames} />}
      </div>
    </>
  );
};
