import _ from "lodash";
import React from "react";
import { useEffectOnce } from "usehooks-ts";

import { AddressComponent } from "../../app/commonOps/AddressComponent";
import {
  GetCustomerById_customerById,
  GetCustomerById_customerById_contact,
} from "../../generated/operation-result-types";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";

type Props = { customerById: GetCustomerById_customerById };

const GetDefaultedContact = (
  contact: GetCustomerById_customerById_contact | null,
): Omit<GetCustomerById_customerById_contact, "id"> => {
  if (!contact) {
    return {
      email: null,
      phoneNumbers: [],
      firstName: "",
      middleName: "",
      lastName: "",
      fullName: "",
      avatarInitials: "",
      __typename: "Contact",
    };
  }
  return contact;
};

export const CustomerProfileView: React.FC<Props> = (props) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Customer profile");
  });

  const {
    name: customerName,
    addresses,
    customerTaxIds,
    contact,
  } = props.customerById;

  const contactById = GetDefaultedContact(contact);
  const { firstName, middleName, lastName, email, phoneNumbers } = contactById;

  const addressRows = addresses.map((address) => {
    const { id: addressId } = address;

    return (
      <div className={"flex flex-row items-center space-x-4"} key={addressId}>
        <div className={"text-sm"}>
          <AddressComponent address={address} />
        </div>
        {/*{<Badge label={"Primary"} colorType={"success"} />}*/}
      </div>
    );
  });

  const phoneNumberRows = phoneNumbers?.map((phoneEdge) => {
    const { id: phoneNumberId, number } = phoneEdge;
    return (
      <div
        className={"flex flex-row items-center space-x-4"}
        key={phoneNumberId}>
        <div className={"text-sm"}>
          <div className={"font-medium text-gray-700"}>{number}</div>
        </div>
        {/*{index === 0 && <Badge label={"Primary"} colorType={"success"} />}*/}
      </div>
    );
  });

  const taxIdRows = customerTaxIds?.map((taxIdEdge) => {
    const { id: taxId, taxIdType, taxIdValue } = taxIdEdge;
    return (
      <div className={"flex justify-between text-sm"} key={taxId}>
        <div className={"text-base-content opacity-60"}>{taxIdType}</div>
        <div className={"text-md font-semibold opacity-80"}>{taxIdValue}</div>
      </div>
    );
  });

  const emailRows = _.compact([email]).map((x) => {
    const { id: emailId, emailAddress } = x;
    return (
      <div className={"flex flex-row items-center space-x-4"} key={emailId}>
        <div className={"text-sm"}>
          <a
            href={`mailto:${emailAddress}`}
            className={"link link-primary text-sm"}>
            {emailAddress}
          </a>
        </div>
        {/*{<Badge label={"Primary"} colorType={"success"} />}{" "}*/}
      </div>
    );
  });

  return (
    <>
      <FormSection name={"Customer Information"}>
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>
            Customer / Company
          </div>
          <div className={"text-md font-semibold opacity-80"}>
            {customerName}
          </div>
        </div>

        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>First name</div>
          <div className={"text-md font-semibold opacity-80"}>{firstName}</div>
        </div>

        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Middle name</div>
          <div className={"text-md font-semibold opacity-80"}>{middleName}</div>
        </div>

        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Last name</div>
          <div className={"text-md font-semibold opacity-80"}>{lastName}</div>
        </div>
      </FormSection>

      <FormSection name={"Emails"}>{emailRows}</FormSection>
      <FormSection name={"Phone numbers"}>{phoneNumberRows}</FormSection>
      <FormSection name={"Addresses"}>{addressRows}</FormSection>
      <FormSection name={"Identifications"}>{taxIdRows}</FormSection>
    </>
  );
};
