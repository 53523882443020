import { useMutation } from "@apollo/client";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { LABELS, LinkConstants } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { Alert } from "../../components/alerts/alert";
import { BrandButton } from "../../components/button/BrandButton";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  OnboardOrg,
  OnboardOrgInput,
  OnboardOrgVariables,
} from "../../generated/operation-result-types";
import { ONBOARD_ORG_GQL } from "../../queries/OnboardingQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";
import { OnboardingLayout } from "./OnboardingLayout";

type Props = {
  orgId: string;
  userId: string;
  onDone: () => void;
};

export const OrgOnboardingView: React.FC<Props> = (props) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Onboarding org");
  });

  const { orgId, userId, onDone } = props;

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardOrgInput>({
    defaultValues: {
      orgId: orgId,
      userId: userId,
      locationAddressInput: {
        country: "Canada",
      },
    },
  });

  const [onboardUser, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<OnboardOrg, OnboardOrgVariables>(ONBOARD_ORG_GQL, {});

  const onSubmit: SubmitHandler<OnboardOrgInput> = async (data) => {
    resetM();
    await onboardUser({
      variables: {
        onboardOrgInput: data,
      },
    });
    onDone();
  };

  const { customerRules, orgRules } = ValidationConstants;
  const orgInfoView = (
    <>
      <div
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Business Name</span>
          </label>
          <input
            type={"text"}
            placeholder={"Your accounting firm name ..."}
            {...register("name", {
              required: LABELS.required,
              ...orgRules.nameRule.valueLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.name?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.name?.message}
            </span>
          )}
        </div>
      </div>
      <div
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 1</span>
          </label>
          <input
            type={"text"}
            autoComplete={"address-line1"}
            {...register("locationAddressInput.line1", {
              required: LABELS.required,
              ...customerRules.addressRule.line1Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.line1?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.line1?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 2</span>
          </label>
          <input
            type={"text"}
            autoComplete={"address-line2"}
            {...register("locationAddressInput.line2", {
              required: false,
              ...customerRules.addressRule.line2Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.line2?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.line2?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>City</span>
          </label>
          <input
            type={"text"}
            autoComplete={"address-level2"}
            {...register("locationAddressInput.city", {
              required: LABELS.required,
              ...customerRules.addressRule.cityLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.city?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.city?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Province / State</span>
          </label>
          <select
            autoComplete={"address-level1"}
            className={"select select-bordered w-full max-w-xs"}
            {...register("locationAddressInput.region", {
              required: LABELS.required,
              ...customerRules.addressRule.regionLength,
            })}>
            {/*CA provinces */}
            <option value={"BC"}>British Columbia</option>
            <option value={"NL"}>Newfoundland</option>
            <option value={"PE"}>Prince Edward Island</option>
            <option value={"NS"}>Nova Scotia</option>
            <option value={"NB"}>New Brunswick</option>
            <option value={"QC"}>Quebec</option>
            <option value={"ON"}>Ontario</option>
            <option value={"MB"}>Manitoba</option>
            <option value={"SK"}>Saskatchewan</option>
            <option value={"AB"}>Alberta</option>
            <option value={"YT"}>Yukon</option>
            <option value={"NT"}>Northwest Territories</option>
            <option value={"NU"}>Nunavut</option>

            {/*  US states */}
            <option value={"AL"}>Alabama</option>
            <option value={"AK"}>Alaska</option>
            <option value={"AS"}>American Samoa</option>
            <option value={"AZ"}>Arizona</option>
            <option value={"AR"}>Arkansas</option>
            <option value={"CA"}>California</option>
            <option value={"CO"}>Colorado</option>
            <option value={"CT"}>Connecticut</option>
            <option value={"DE"}>Delaware</option>
            <option value={"DC"}>District Of Columbia</option>
            <option value={"FL"}>Florida</option>
            <option value={"GA"}>Georgia</option>
            <option value={"GU"}>Guam</option>
            <option value={"HI"}>Hawaii</option>
            <option value={"ID"}>Idaho</option>
            <option value={"IL"}>Illinois</option>
            <option value={"IN"}>Indiana</option>
            <option value={"IA"}>Iowa</option>
            <option value={"KS"}>Kansas</option>
            <option value={"KY"}>Kentucky</option>
            <option value={"LA"}>Louisiana</option>
            <option value={"ME"}>Maine</option>
            <option value={"MD"}>Maryland</option>
            <option value={"MA"}>Massachusetts</option>
            <option value={"MI"}>Michigan</option>
            <option value={"MN"}>Minnesota</option>
            <option value={"MS"}>Mississippi</option>
            <option value={"MO"}>Missouri</option>
            <option value={"MT"}>Montana</option>
            <option value={"NE"}>Nebraska</option>
            <option value={"NV"}>Nevada</option>
            <option value={"NH"}>New Hampshire</option>
            <option value={"NJ"}>New Jersey</option>
            <option value={"NM"}>New Mexico</option>
            <option value={"NY"}>New York</option>
            <option value={"NC"}>North Carolina</option>
            <option value={"ND"}>North Dakota</option>
            <option value={"MP"}>Northern Mariana IS</option>
            <option value={"OH"}>Ohio</option>
            <option value={"OK"}>Oklahoma</option>
            <option value={"OR"}>Oregon</option>
            <option value={"PA"}>Pennsylvania</option>
            <option value={"PR"}>Puerto Rico</option>
            <option value={"RI"}>Rhode Island</option>
            <option value={"SC"}>South Carolina</option>
            <option value={"SD"}>South Dakota</option>
            <option value={"TN"}>Tennessee</option>
            <option value={"TX"}>Texas</option>
            <option value={"UT"}>Utah</option>
            <option value={"VT"}>Vermont</option>
            <option value={"VI"}>Virgin Islands</option>
            <option value={"VA"}>Virginia</option>
            <option value={"WA"}>Washington</option>
            <option value={"WV"}>West Virginia</option>
            <option value={"WI"}>Wisconsin</option>
            <option value={"WY"}>Wyoming</option>
          </select>
          {errors?.locationAddressInput?.region?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.region?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Country</span>
          </label>
          <select
            autoComplete={"country"}
            className={"select select-bordered w-full max-w-xs"}
            {...register("locationAddressInput.country", {
              required: LABELS.required,
              ...customerRules.addressRule.countryLength,
            })}>
            <option value={"CA"}>Canada</option>
            <option value={"US"}>US</option>
          </select>
          {errors?.locationAddressInput?.country?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.country?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Postal / Zip Code</span>
          </label>
          <input
            type={"text"}
            autoComplete={"postal-code"}
            {...register("locationAddressInput.postalCode", {
              required: LABELS.required,
            })}
            className={"input input-bordered"}
          />
          {errors?.locationAddressInput?.postalCode?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.locationAddressInput?.postalCode?.message}
            </span>
          )}
        </div>
      </div>
    </>
  );

  const freePlanView = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6"
      }>
      <div className={"form-control space-y-4"}>
        <div className={"flex"}>
          <div className={"flex h-5 items-center"}>
            <input
              type={"checkbox"}
              className={"checkbox checkbox-primary"}
              disabled={loadingM}
              {...register("subscribeFreePlan", {
                required: LABELS.required,
              })}
            />
          </div>
          <div className={"ml-3 flex flex-col text-sm"}>
            <label className={"font-medium text-gray-700"}>
              Free trial plan{" "}
              <a
                href={LinkConstants.bankStatements.pricing}
                className={"link link-primary"}
                target={"_blank"}
                rel={"noreferrer"}>
                View plan details
              </a>
            </label>
            {errors?.subscribeFreePlan?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.subscribeFreePlan?.message}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <OnboardingLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"My Firm's Information"}>{orgInfoView}</FormSection>
        <FormSection name={"Subscription Plan"}>{freePlanView}</FormSection>

        {errorM && <Alert type={"error"} label={LABELS.errors.default} />}
        <BrandButton
          buttonType={"submit"}
          colorType={"primary"}
          label={"Continue"}
          SvgIconLeft={AddStackSvg}
          disabled={loadingM}
        />
      </form>
    </OnboardingLayout>
  );
};
