import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { getFormattedCurrency } from "../../../app/commonOps/CurrencyOps";
import { INTERNAL } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { BrandButton } from "../../../components/button/BrandButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  PricingColumns,
  PricingTiersT,
} from "../../../components/pricing/PricingColumns";
import {
  GetSubscriptionPlans,
  GetSubscriptionPlans_orgBillings_edges_node_subscriptionPlans,
  GetSubscriptionPlansVariables,
} from "../../../generated/operation-result-types";
import { GET_SUBSCRIPTION_PLANS_GQL } from "../../../queries/OrgBillingQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { SubscriptionFaqsView } from "./SubscriptionFaqsView";
import { SubscriptionPlanDetailsView } from "./SubscriptionPlanDetailsView";

const HIDE_PLANS_TEMP = false;

export const SubscriptionPlansView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Firm plans");
  });

  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orgId = userInfoByEmail!.org!.id;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetSubscriptionPlans, GetSubscriptionPlansVariables>(
    GET_SUBSCRIPTION_PLANS_GQL,
    {
      fetchPolicy: "network-only",
      variables: {
        orgId: orgId,
      },
    },
  );

  const [selectedPlan, setSelectedPlan] = React.useState<
    GetSubscriptionPlans_orgBillings_edges_node_subscriptionPlans | undefined
  >(undefined);

  if (loadingQ) {
    return <LottieLoading />;
  }
  if (errorQ) {
    throw new Error("Error getting SubscriptionPlansView");
  }

  const orgBilling = _.first(dataQ?.orgBillings?.edges)?.node;
  if (!orgBilling) {
    throw new Error("Missing billing info");
  }

  const { subscriptionPlans, paymentInfo } = orgBilling;

  const subscriptionPlansTiers: PricingTiersT[] = subscriptionPlans.map(
    (subscriptionPlan) => {
      const {
        id,
        name,
        isCurrent,
        canChoose,
        primaryAmount,
        primaryUnit,
        additionalAmount,
        additionalUnit,
        features,
        flairText,
        primaryDuration,
      } = subscriptionPlan;
      const buttonLabel = isCurrent
        ? "Current Plan"
        : canChoose
        ? "Select Plan"
        : "Offer Expired";

      return {
        name: name,
        primaryAmount: getFormattedCurrency(primaryAmount),
        primaryUnit: primaryUnit,
        secondaryAmount: additionalAmount
          ? getFormattedCurrency(additionalAmount)
          : undefined,
        secondaryUnit: additionalUnit ?? undefined,
        items: features,
        primaryDuration,
        action: (
          <BrandButton
            colorType={"primary"}
            label={buttonLabel}
            disabled={isCurrent || !canChoose}
            onClick={() => setSelectedPlan(subscriptionPlan)}
          />
        ),
        flairText,
      };
    },
  );

  const pricingColumns = <PricingColumns tiers={subscriptionPlansTiers} />;
  const contactUsForPlans = (
    <div>
      <div>
        <a
          href={`mailto:${INTERNAL.emails.support}?subject=Pricing%20plans%20Inquiry&body=ProCharted%20Support%2C%0A%0ACan%20you%20provide%20the%20plans%20and%20their%20price%3F`}
          className={"btn btn-primary"}>
          {"Contact us now"}
        </a>
      </div>
    </div>
  );

  return (
    <>
      <FormSection name={"Subscription Plans"}>
        <div className={"mx-auto mb-8 w-full"}>
          {HIDE_PLANS_TEMP ? contactUsForPlans : pricingColumns}
        </div>
      </FormSection>
      <SubscriptionFaqsView />
      {selectedPlan && (
        <div className={"mb-10"}>
          <SubscriptionPlanDetailsView
            orgId={orgId}
            selectedPlan={selectedPlan}
            paymentInfo={paymentInfo}
            onSuccess={() => {
              navigate("../billing");
            }}
          />
        </div>
      )}
    </>
  );
};
