import React from "react";

import { LogoText } from "./LogoText";

export const LogoLink: React.FC<unknown> = () => {
  return (
    <a
      href={"/home"}
      className={"flex-0 nuxt-link-active btn btn-ghost px-2 md:px-4"}
      aria-label={"Home"}>
      <LogoText />
    </a>
  );
};
