import { FileUploader } from "baseui/file-uploader";
import * as React from "react";

import { Alert } from "../alerts/alert";

type FileMessage = {
  status: "valid" | "invalid";
  message: string;
};

const GetFileMessage = (file: File): FileMessage => {
  const minSizeInKb = 10;
  const maxSizeInMb = 25;
  if (file.size > maxSizeInMb * 1024 * 1024) {
    return {
      status: "invalid",
      message: `${file.name} exceeds max allowed file size of ${maxSizeInMb} MB`,
    };
  }

  if (file.size < minSizeInKb * 1024) {
    return {
      status: "invalid",
      message: `${file.name} is too small. File must be a minimum size of ${minSizeInKb} KB`,
    };
  }

  return { status: "valid", message: `Selected file ${file.name}` };
};

const GetFileMessageAlert = (fileMessage: FileMessage): JSX.Element => {
  if (fileMessage.status === "invalid") {
    return <Alert type={"error"} label={fileMessage.message} />;
  } else {
    return <Alert type={"success"} label={fileMessage.message} />;
  }
};

type Props = {
  onFileSelected: (file?: File) => void;
  progressAmount: number;
};

export const FileUploaderView: React.FC<Props> = (props) => {
  const { onFileSelected, progressAmount } = props;

  let progressMessage: string;
  if (progressAmount >= 98) progressMessage = "Saving...";
  else if (progressAmount > 0)
    progressMessage = `Uploading... ${progressAmount}% of 100%`;
  else progressMessage = "";

  const [fileMessage, setFileMessage] = React.useState<FileMessage | undefined>(
    undefined,
  );

  return (
    <>
      {fileMessage && GetFileMessageAlert(fileMessage)}
      <FileUploader
        accept={[".pdf"]}
        multiple={false}
        disabled={progressAmount > 0}
        onDrop={(acceptedFiles, rejectedFiles) => {
          const selectedFile = acceptedFiles[0];

          const fm = GetFileMessage(selectedFile);
          setFileMessage(fm);
          if (fm.status === "valid") {
            onFileSelected(selectedFile);
          } else {
            onFileSelected(undefined);
          }
        }}
        name={"BankDocFileUploader"}
        progressAmount={progressAmount}
        progressMessage={progressMessage}
      />
    </>
  );
};
