import React from "react";
import { match } from "ts-pattern";

type Props = {
  size: "extra-small" | "small" | "medium" | "large";
  label: string;
};

export const AvatarPlaceholder: React.FC<Props> = (props) => {
  const { size, label } = props;

  const { avatarSizeCx, textSizeCx } = match(size)
    .with("large", () => {
      return { avatarSizeCx: "w-32 h-32", textSizeCx: "text-3xl" };
    })
    .with("medium", () => {
      return { avatarSizeCx: "w-24 h-24", textSizeCx: "text-xl" };
    })
    .with("small", () => {
      return { avatarSizeCx: "w-16 h-16", textSizeCx: "" };
    })
    .with("extra-small", () => {
      return { avatarSizeCx: "w-10 h-10", textSizeCx: "" };
    })
    .exhaustive();

  return (
    <>
      <div className={"avatar placeholder"}>
        <div
          className={`rounded-full bg-neutral-focus text-neutral-content ${avatarSizeCx}`}>
          <span className={textSizeCx}>{label}</span>
        </div>
      </div>
    </>
  );
};
