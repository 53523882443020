import React from "react";
import { useLocation } from "react-router-dom";

import { BrandConstants } from "../app/constants/TextConstants";

export const ScrollToTop: React.FC<unknown> = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // window.scrollTo(0, 0);

    document
      .querySelector("#proc-main-view")
      ?.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  return null;
};

export const GetPageTitle = (title: string): string => {
  return `${title} | ${BrandConstants.name}`;
};
