import { useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { useEffectOnce } from "usehooks-ts";

import { AddressComponent } from "../../../app/commonOps/AddressComponent";
import { LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetLocationsByOrgId,
  GetLocationsByOrgIdVariables,
} from "../../../generated/operation-result-types";
import { GET_LOCATIONS_BY_ORG_ID_GQL } from "../../../queries/LocationQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const LocationsListView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Firm locations");
  });

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = useQuery<GetLocationsByOrgId, GetLocationsByOrgIdVariables>(
    GET_LOCATIONS_BY_ORG_ID_GQL,
    {
      variables: {
        orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }
  if (errorQ) {
    console.error("LocationsListView | LocationsListView", { errorQ });
    throw new Error("Error getting LocationsListView");
  }

  const locations = dataQ?.orgById.locations;
  if (!locations) {
    return <p>Missing locations</p>;
  }

  const locationRows = locations.edges?.map((locationEdge) => {
    const { id, locationAddress } = locationEdge.node;

    return (
      <tr key={id}>
        <td>
          <div className={"flex items-center"}>
            <div className={"flex flex-col"}>
              <div className={"capitalize"}>
                {locationAddress?.city} {locationAddress?.region}
              </div>
            </div>
          </div>
        </td>
        <td>
          {" "}
          <div className={"text-sm"}>
            {locationAddress && <AddressComponent address={locationAddress} />}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <FormSection name={"Locations"}>
        {/*<div className={"flex justify-end pb-4"}>*/}
        {/*  <OutlineButton*/}
        {/*    colorType={"link"}*/}
        {/*    label={"Add Location"}*/}
        {/*    LeftIconSvg={AddStackSvg}*/}
        {/*    onClick={() => navigate("./add")}*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          className={
            "card card-body my-6 overflow-x-auto border-base-300 bg-base-100 px-0"
          }>
          <table className={"table-compact table w-full md:table-normal"}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>{locationRows}</tbody>
          </table>
        </div>
      </FormSection>
    </>
  );
};
