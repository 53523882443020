import { useMutation } from "@apollo/client";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import { List } from "baseui/dnd-list";
import React from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { BW_STYLES } from "../../app/constants/CssConstants";
import { LABELS } from "../../app/constants/TextConstants";
import { ValidationConstants } from "../../app/constants/ValidationConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { Alert } from "../../components/alerts/alert";
import { BrandButton } from "../../components/button/BrandButton";
import { OutlineButton } from "../../components/button/OutlineButton";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  AddContactEmailInput,
  AddContactPhoneNumberInput,
  AddCustomer,
  AddCustomerAddressInput,
  AddCustomerInput,
  AddCustomerTaxIdInput,
  AddCustomerVariables,
} from "../../generated/operation-result-types";
import { ADD_CUSTOMER_GQL } from "../../queries/CustomerQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";

export const AddCustomerView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Add Customer");
  });

  const navigate = useNavigate();

  // app insights
  const appInsights = useAppInsightsContext();
  const trackCustomerAdded = useTrackEvent(appInsights, "Customer Added", {});

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation, userInfoByEmail } = userInfoState.context;

  // field defaults
  const addressDefaultValues: AddCustomerAddressInput = {
    line1: "",
    city: "",
    country: "",
    postalCode: "",
    region: "",
    addressTypeEnumId:
      "QWRkcmVzc1R5cGVFbnVtCmcxMmY3M2M3Mzg5ZTU0ZjY5OWYxNGVkN2ExZmZjMzA4MA==",
  };
  const emailDefaultValues: AddContactEmailInput = {
    emailAddress: "",
  };
  const phoneDefaultValues: AddContactPhoneNumberInput = {
    number: "",
    phoneNumberTypeEnumId:
      "UGhvbmVOdW1iZXJUeXBlRW51bQpnYmNkNjk3YThlZjRjNGY5MWJiOGY4OTFkZjFkZGZiYTA=",
  };
  const customerTaxIdDefaultValues: AddCustomerTaxIdInput = {
    taxIdType: "",
    taxIdValue: "",
  };

  // RHF
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<AddCustomerInput>({
    defaultValues: {
      orgId: userInfoByEmail?.org?.id,
      locationId: currentLocation?.id,
      addCustomerTaxIdInputs: [],
      addContactInput: {
        firstName: "",
        lastName: "",
        addContactPhoneNumberInputs: [],
        addContactEmailInput: emailDefaultValues,
      },
      addCustomerAddressInputs: [],
    },
  });
  const {
    fields: phonesFields,
    append: phonesAppend,
    remove: phonesRemove,
    move: phonesMove,
  } = useFieldArray({
    control,
    name: "addContactInput.addContactPhoneNumberInputs",
  });
  const phonesAppendWithDefaults = () => {
    phonesAppend({
      ...phoneDefaultValues,
    });
  };
  const {
    fields: addressesFields,
    append: addressesAppend,
    remove: addressesRemove,
    move: addressesMove,
  } = useFieldArray({
    control,
    name: "addCustomerAddressInputs",
  });
  const addressesAppendWithDefaults = () => {
    addressesAppend({
      ...addressDefaultValues,
    });
  };
  const {
    fields: taxIdFields,
    append: taxIdAppend,
    remove: taxIdRemove,
    move: taxIdMove,
  } = useFieldArray({
    control,
    name: "addCustomerTaxIdInputs",
  });
  const taxIdAppendWithDefaults = () => {
    taxIdAppend(customerTaxIdDefaultValues);
  };

  // mutation
  const [addCustomer, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<AddCustomer, AddCustomerVariables>(ADD_CUSTOMER_GQL, {});

  // local effects
  React.useEffect(() => {
    setFocus("customerName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RHF submit handler
  const onSubmit: SubmitHandler<AddCustomerInput> = async (data) => {
    resetM();
    await addCustomer({
      variables: {
        addCustomerInput: data,
      },
    });
    trackCustomerAdded({ name: data.customerName });
    if (!errorM) {
      navigate("../");
    }
  };

  const { nameRule, profileRules, phoneNumberRule, addressRule, taxIdRule } =
    ValidationConstants.customerRules;

  const addressesRows = addressesFields.map((address, index) => {
    return (
      <div
        key={address.id}
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 1 {LABELS.optional}</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerAddressInputs.${index}.line1`, {
              required: false,
              ...addressRule.line1Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerAddressInputs?.[index]?.line1?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerAddressInputs?.[index]?.line1?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Line 2 {LABELS.optional}</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerAddressInputs.${index}.line2`, {
              required: false,
              ...addressRule.line2Length,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerAddressInputs?.[index]?.line2?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerAddressInputs?.[index]?.line2?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>City</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerAddressInputs.${index}.city`, {
              ...addressRule.cityLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerAddressInputs?.[index]?.city?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerAddressInputs?.[index]?.city?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Province / State</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerAddressInputs.${index}.region`, {
              ...addressRule.regionLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerAddressInputs?.[index]?.region?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerAddressInputs?.[index]?.region?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Country</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerAddressInputs.${index}.country`, {
              required: LABELS.required,
              ...addressRule.countryLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerAddressInputs?.[index]?.country?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerAddressInputs?.[index]?.country?.message}
            </span>
          )}
        </div>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Postal / Zip Code</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerAddressInputs.${index}.postalCode`, {
              ...addressRule.postalCodeLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerAddressInputs?.[index]?.postalCode?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerAddressInputs?.[index]?.postalCode?.message}
            </span>
          )}
        </div>
        <BrandButton
          colorType={"link"}
          label={"Remove address"}
          onClick={() => addressesRemove(index)}
        />
      </div>
    );
  });

  const phoneNumberRows = phonesFields.map((phoneNumber, index) => {
    return (
      <div
        key={phoneNumber.id}
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Phone number</span>
          </label>
          <input
            type={"text"}
            {...register(
              `addContactInput.addContactPhoneNumberInputs.${index}.number`,
              {
                required: LABELS.required,
                ...phoneNumberRule.valueLength,
              },
            )}
            className={"input input-bordered"}
          />
          {errors?.addContactInput?.addContactPhoneNumberInputs?.[index]?.number
            ?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {
                errors?.addContactInput?.addContactPhoneNumberInputs?.[index]
                  ?.number?.message
              }
            </span>
          )}
        </div>

        <BrandButton
          colorType={"link"}
          label={"Remove phone"}
          onClick={() => phonesRemove(index)}
        />
      </div>
    );
  });
  const taxIdRows = taxIdFields.map((taxIdField, index) => {
    return (
      <div
        key={taxIdField.id}
        className={
          "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
        }>
        <div className={"form-control"}>
          <label className={"label"}>
            <div className={"label-text"}>Identification Name</div>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerTaxIdInputs.${index}.taxIdType` as const, {
              required: LABELS.required,
              ...taxIdRule.nameLength,
            })}
            placeholder={"Example: Business number, SIN, SSN etc."}
            className={"input input-bordered"}
          />
          {errors?.addCustomerTaxIdInputs?.[index]?.taxIdType?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerTaxIdInputs?.[index]?.taxIdType?.message}
            </span>
          )}
        </div>

        <div className={"form-control"}>
          <label className={"label"}>
            <span className={"label-text"}>Identification Number</span>
          </label>
          <input
            type={"text"}
            {...register(`addCustomerTaxIdInputs.${index}.taxIdValue`, {
              required: LABELS.required,
              ...taxIdRule.valueLength,
            })}
            className={"input input-bordered"}
          />
          {errors?.addCustomerTaxIdInputs?.[index]?.taxIdValue?.message && (
            <span className={"pt-2 text-sm font-bold text-error"}>
              {errors?.addCustomerTaxIdInputs?.[index]?.taxIdValue?.message}
            </span>
          )}
        </div>

        <BrandButton
          colorType={"link"}
          label={"Remove identification"}
          onClick={() => taxIdRemove(index)}
        />
      </div>
    );
  });

  const emailRows = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-3"
      }>
      <div className={"form-control"}>
        <label className={"label"}>
          <span className={"label-text"}>Email *</span>
        </label>
        <input
          type={"text"}
          {...register("addContactInput.addContactEmailInput.emailAddress", {
            required: LABELS.required,
            ...profileRules.emailLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.addContactInput?.addContactEmailInput?.emailAddress
          ?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {
              errors?.addContactInput?.addContactEmailInput?.emailAddress
                ?.message
            }
          </span>
        )}
      </div>
    </div>
  );

  const customerInfoRow = (
    <div
      className={
        "grid grid-cols-1 items-center gap-4 rounded rounded-xl border border-base-300 bg-base-100 p-4 pb-6 lg:grid-cols-5"
      }>
      <div className={"form-control lg:col-span-5"}>
        <label className={"label"}>
          <span className={"label-text"}>Customer / Business name *</span>
        </label>
        <input
          type={"text"}
          {...register("customerName", {
            required: LABELS.required,
            ...nameRule.valueLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.customerName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.customerName?.message}
          </span>
        )}
      </div>
      <div className={"form-control lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>First name</span>
        </label>
        <input
          type={"text"}
          {...register("addContactInput.firstName", {
            required: false,
            ...profileRules.firstNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.addContactInput?.firstName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.addContactInput?.firstName?.message}
          </span>
        )}
      </div>

      <div className={"form-control lg:col-span-1"}>
        <label className={"label"}>
          <span className={"label-text"}>Initials</span>
        </label>
        <input
          type={"text"}
          {...register("addContactInput.middleName", {
            required: false,
            ...profileRules.middleNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.addContactInput?.middleName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.addContactInput?.middleName?.message}
          </span>
        )}
      </div>

      <div className={"form-control lg:col-span-2"}>
        <label className={"label"}>
          <span className={"label-text"}>Last name</span>
        </label>
        <input
          type={"text"}
          {...register("addContactInput.lastName", {
            required: false,
            ...profileRules.lastNameLength,
          })}
          className={"input input-bordered"}
        />
        {errors?.addContactInput?.lastName?.message && (
          <span className={"pt-2 text-sm font-bold text-error"}>
            {errors?.addContactInput?.lastName?.message}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <>
      {/*<DevTool control={control} placement={"bottom-right"} />*/}
      <div className={"pb-4 text-xl font-semibold"}>Add New Customer</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"Customer Information"}>
          {customerInfoRow}
        </FormSection>

        <FormSection name={"Email addresses"}>{emailRows}</FormSection>

        <FormSection name={"Phone numbers"}>
          <List
            items={phoneNumberRows}
            overrides={BW_STYLES.listOverrides}
            onChange={({ oldIndex, newIndex }) => {
              phonesMove(oldIndex, newIndex);
            }}
          />

          <BrandButton
            colorType={"link"}
            label={"Add phone number"}
            onClick={phonesAppendWithDefaults}
          />
        </FormSection>

        <FormSection name={"Addresses"}>
          <List
            items={addressesRows}
            overrides={BW_STYLES.listOverrides}
            onChange={({ oldIndex, newIndex }) => {
              addressesMove(oldIndex, newIndex);
            }}
          />

          <BrandButton
            colorType={"link"}
            label={"Add address"}
            onClick={addressesAppendWithDefaults}
          />
        </FormSection>

        <FormSection
          name={"Identification"}
          description={"Add a business number, GST/HST number etc."}>
          <List
            items={taxIdRows}
            overrides={BW_STYLES.listOverrides}
            onChange={({ oldIndex, newIndex }) => {
              taxIdMove(oldIndex, newIndex);
            }}
          />

          <BrandButton
            colorType={"link"}
            label={"Add identification"}
            onClick={taxIdAppendWithDefaults}
          />
        </FormSection>

        {errorM && <Alert type={"error"} label={LABELS.errors.default} />}

        <div className={"flex justify-start space-x-4 pb-4"}>
          <BrandButton
            buttonType={"submit"}
            colorType={"primary"}
            label={"Create Customer"}
            SvgIconLeft={AddStackSvg}
            disabled={loadingM}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
          />
        </div>
      </form>
    </>
  );
};
