import { gql } from "@apollo/client";

export const GET_USERS_BY_ORG_ID_GQL = gql`
  query GetUsersByOrgId($orgId: ID!) {
    orgById(id: $orgId) {
      id
      legalName

      users {
        edges {
          node {
            id
            userRoleEnum {
              id
              userRole
            }
            userProfile {
              id
              fullName
              avatarInitials
              email
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_INFO_BY_EMAIL_GQL = gql`
  query GetUserInfoByEmail($email: String!) {
    users(where: { userProfile: { email: { eq: $email } } }) {
      edges {
        node {
          id
          userProfile {
            id
            fullName
            avatarInitials
            email
          }

          userRoleEnum {
            id
            userRole
          }

          org {
            id
            legalName
            locations {
              edges {
                node {
                  id
                  locationAddress {
                    id
                    city
                    region
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_QUICKSTART_GQL = gql`
  mutation UpdateUserQuickStart($input: UpdateUserQuickStartDoneInput!) {
    updateUserQuickStartDone(input: $input) {
      userFeatureFlag {
        id
        quickStartV1Completed
      }
    }
  }
`;

export const GET_USER_FEATURE_FLAGS_GQL = gql`
  query GetUserFeatureFlags($userId: ID!) {
    userFeatureFlags(where: { user: { id: { eq: $userId } } }) {
      edges {
        node {
          id
          quickStartV1Completed
        }
      }
    }
  }
`;
