import { useMutation, useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { BrandButton } from "../../../components/button/BrandButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetOrgById,
  GetOrgByIdVariables,
  RenameOrg,
  RenameOrgInput,
  RenameOrgVariables,
} from "../../../generated/operation-result-types";
import {
  GET_ORG_BY_ID_GQL,
  RENAME_ORG_GQL,
} from "../../../queries/OrgQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const EditOrgDetails: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Edit firm details");
  });

  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  // query
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = useQuery<GetOrgById, GetOrgByIdVariables>(GET_ORG_BY_ID_GQL, {
    variables: {
      orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
    },
  });

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RenameOrgInput>({
    defaultValues: {
      id: userInfoByEmail?.org?.id,
    },
  });

  // mutation
  const [renameOrg, { loading: loadingM, error: errorM }] = useMutation<
    RenameOrg,
    RenameOrgVariables
  >(RENAME_ORG_GQL, {
    refetchQueries: [
      {
        query: GET_ORG_BY_ID_GQL,
        variables: {
          orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
        },
      },
    ],
  });

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("EditOrgDetails | EditOrgDetails", { errorQ });
    throw new Error("Error getting EditOrgDetails");
  }

  const getOrgQResp = dataQ?.orgById;
  if (!getOrgQResp) {
    return <p>Missing firm details</p>;
  }

  const onSubmit: SubmitHandler<RenameOrgInput> = async (data) => {
    await renameOrg({
      variables: {
        input: data,
      },
    });
    if (!errorM) {
      navigate("../");
    }
  };

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Edit Firm Details</div>

      <FormSection name={"Firm Info"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"form-control"}>
            <label className={"label"}>
              <span className={"label-text"}>Name</span>
            </label>
            <input
              type={"text"}
              defaultValue={getOrgQResp.legalName}
              {...register("name", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
              })}
              className={"input input-bordered"}
            />
            {errors.name?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors.name?.message}
              </span>
            )}
          </div>

          <div className={"py-4"}>
            <BrandButton
              colorType={"primary"}
              label={"Update"}
              buttonType={"submit"}
              disabled={loadingM}
            />
          </div>
        </form>
      </FormSection>

      {errorM && <Alert type={"error"} label={LABELS.errors.default} />}
    </>
  );
};
