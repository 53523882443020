import React from "react";

type Props = {
  title: string;
};

export const PageLayout: React.FC<Props> = (props) => {
  const { title, children } = props;
  return (
    <div className={"w-full px-10 pb-10"}>
      <h1 className={"text-3xl font-bold capitalize"}>{title}</h1>
      {children}
    </div>
  );
};
