import { useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { useEffectOnce } from "usehooks-ts";

import { INTERNAL, LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetUsersByOrgId,
  GetUsersByOrgIdVariables,
} from "../../../generated/operation-result-types";
import { GET_USERS_BY_ORG_ID_GQL } from "../../../queries/UserQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";

export const UsersListView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Firm users");
  });

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = useQuery<GetUsersByOrgId, GetUsersByOrgIdVariables>(
    GET_USERS_BY_ORG_ID_GQL,
    {
      variables: {
        orgId: userInfoByEmail?.org?.id ?? LABELS.errors.noOrgId,
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }
  if (errorQ) {
    console.error("UsersListView | UsersListView", { errorQ });
    throw new Error("Error getting UsersListView");
  }

  const getUsersResp = dataQ?.orgById.users;
  if (!getUsersResp) {
    return <p>Missing users</p>;
  }

  const userRows = getUsersResp.edges?.map((userEdge) => {
    const { id, userProfile, userRoleEnum } = userEdge.node;

    return (
      <tr key={id}>
        <td>
          <div className={"flex items-center"}>
            <AvatarPlaceholder
              size={"extra-small"}
              label={
                userProfile?.avatarInitials ??
                LABELS.unknownUser.defaultInitials
              }
            />
            <div className={"flex flex-col pl-2"}>
              <div className={"capitalize"}>{userProfile?.fullName}</div>
            </div>
          </div>
        </td>
        <td>{userProfile?.email}</td>
        <td>
          <div className={"flex items-center"}>
            <div className={"badge badge-outline capitalize"}>
              {userRoleEnum?.userRole}
            </div>
          </div>
        </td>
        <td>
          <div className={"flex items-center"}>Invited</div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <FormSection name={"Users"}>
        <div className={"flex justify-end pb-4"}>
          <a
            href={`mailto:${INTERNAL.emails.support}?subject=Add%20user%20request&body=ProCharted%20Support%2C%0A%0APlease%20add%20the%20following%20user%20to%20my%20firm%3A%0A%0A-%20Email%20address%3A%0A-%20Name%3A%0A%0A`}
            className={"btn btn-outline btn-secondary"}>
            Add User
          </a>
        </div>
        <div
          className={
            "card card-body my-6 overflow-x-auto border-base-300 bg-base-100 px-0"
          }>
          <table className={"table-compact table w-full md:table-normal"}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{userRows}</tbody>
          </table>
        </div>
      </FormSection>
    </>
  );
};
