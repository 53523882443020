import { useMutation } from "@apollo/client";
import * as Apollo from "@apollo/client";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import { OpenLinkInNewTab } from "../../app/commonOps/LinkOps";
import {
  LABELS,
  LinkConstants,
  PATHS,
} from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { OutlineButton } from "../../components/button/OutlineButton";
import { ArrowChevronRightSvg } from "../../components/svg/ArrowChevronRightSvg";
import { SuccessCheckSvg } from "../../components/svg/SuccessCheckSvg";
import {
  GetUserFeatureFlags,
  GetUserFeatureFlagsVariables,
  UpdateUserQuickStart,
  UpdateUserQuickStartVariables,
} from "../../generated/operation-result-types";
import PushingButtons from "../../media/illustrations/pushingButtonsSvg";
import {
  GET_USER_FEATURE_FLAGS_GQL,
  UPDATE_USER_QUICKSTART_GQL,
} from "../../queries/UserQueries.gql";
import { FormSection } from "../../support/FormSection";

export const HomeQuickStartView: React.FC<unknown> = () => {
  const navigate = useNavigate();

  // app insights
  const appInsights = useAppInsightsContext();
  const trackQuickstartClosed = useTrackEvent(
    appInsights,
    "Home quickstart closed",
    {},
  );

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orgId = userInfoByEmail!.org!.id;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userId = userInfoByEmail!.id;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
    refetch,
  } = Apollo.useQuery<GetUserFeatureFlags, GetUserFeatureFlagsVariables>(
    GET_USER_FEATURE_FLAGS_GQL,
    {
      fetchPolicy: "network-only",
      variables: {
        userId: userId,
      },
    },
  );

  const [
    updateQuickStart,
    { loading: loadingM, error: errorM, reset: resetM },
  ] = useMutation<UpdateUserQuickStart, UpdateUserQuickStartVariables>(
    UPDATE_USER_QUICKSTART_GQL,
    { variables: { input: { orgId: orgId, userId: userId } } },
  );

  if (loadingQ) {
    return null;
  }

  if (errorQ) {
    throw new Error("Error getting HomeQuickStartView");
  }

  const userFeatureFlag = _.first(dataQ?.userFeatureFlags?.edges)?.node;
  if (!userFeatureFlag) {
    throw new Error("Missing user feature flag");
  }

  if (userFeatureFlag.quickStartV1Completed) {
    return null;
  }

  const onClick = async () => {
    resetM();
    await updateQuickStart({});
    trackQuickstartClosed({});
    await refetch();
  };

  return (
    <FormSection name={"Get started with ProCharted"}>
      <div className={"grid h-96 grid-cols-3"}>
        {/* left side list */}
        <div className={"col-span-1 flex-col"}>
          <div className={"my-4 flex items-center text-gray-600"}>
            <div className={"text-accent"}>
              <SuccessCheckSvg />
            </div>
            <div className={"flex space-x-2"}>
              <div>Add a customer</div>
              <OutlineButton
                colorType={"primary"}
                label={LABELS.buttons.clickHere}
                size={"extra-small"}
                SvgIconRight={ArrowChevronRightSvg}
                onClick={() => navigate(PATHS.customerAdd())}
              />
            </div>
          </div>
          <div className={"my-4 flex items-center text-gray-600"}>
            <div className={"text-accent"}>
              <SuccessCheckSvg />
            </div>
            <div className={"flex space-x-2"}>
              <div>Upload a statement</div>
              <OutlineButton
                colorType={"primary"}
                label={LABELS.buttons.clickHere}
                size={"extra-small"}
                SvgIconRight={ArrowChevronRightSvg}
                onClick={() => navigate(PATHS.bankDocAdd())}
              />
            </div>
          </div>
          <div className={"my-4 flex items-center text-gray-600"}>
            <div className={"text-accent"}>
              <SuccessCheckSvg />
            </div>
            <div className={"flex space-x-2"}>
              <div>View and download the results</div>
              <OutlineButton
                colorType={"primary"}
                label={LABELS.buttons.clickHere}
                size={"extra-small"}
                SvgIconRight={ArrowChevronRightSvg}
                onClick={() => navigate(PATHS.bankDocs())}
              />
            </div>
          </div>
          <div className={"my-4 flex items-center text-gray-600"}>
            <div className={"text-accent"}>
              <SuccessCheckSvg />
            </div>
            <div className={"flex space-x-2"}>
              <div>Bookmark sign-in page in your browser</div>
              <OutlineButton
                colorType={"primary"}
                label={LABELS.buttons.clickHere}
                size={"extra-small"}
                SvgIconRight={ArrowChevronRightSvg}
                onClick={() => OpenLinkInNewTab(LinkConstants.signIn.index)}
              />
            </div>
          </div>
          <div className={"py-4"}>
            <OutlineButton
              colorType={"secondary"}
              label={"Mark as done"}
              onClick={onClick}
              disabled={loadingM}
            />
          </div>
        </div>

        {/* right side image */}
        <div className={"col-span-2 flex hidden justify-center md:block"}>
          <PushingButtons />
        </div>
      </div>
    </FormSection>
  );
};
