import Lottie from "lottie-react";
import React from "react";

import * as procLoadingAnimation from "../../media/lottieFiles/proc-loading-01.json";

export const LottieLoading: React.FC<unknown> = () => {
  return (
    <div className={"flex flex-col items-center justify-center"}>
      <div className={"h-1/5 w-1/5"}>
        <Lottie animationData={procLoadingAnimation} loop={true} />
      </div>
      <div className={"font-extrabold opacity-30"}>Loading ...</div>
    </div>
  );
};
