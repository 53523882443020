import * as Apollo from "@apollo/client";
import { useMutation } from "@apollo/client";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";
import { Dialog, Transition } from "@headlessui/react";
import * as Xstate from "@xstate/react";
import React, { Fragment, useState } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

import { LABELS, PATHS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { Alert } from "../../components/alerts/alert";
import { BrandButton } from "../../components/button/BrandButton";
import { IconButton } from "../../components/button/IconButton";
import { OutlineButton } from "../../components/button/OutlineButton";
import { Dropdown } from "../../components/dropdown/Dropdown";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { PageLayout } from "../../components/layouts/PageLayout";
import { ArrowChevronDownSvg } from "../../components/svg/ArrowChevronDownSvg";
import {
  CustomerStateEnum,
  GetCustomerById,
  GetCustomerByIdVariables,
  UpdateCustomerState,
  UpdateCustomerStateVariables,
} from "../../generated/operation-result-types";
import {
  GET_CUSTOMER_BY_ID_GQL,
  UPDATE_CUSTOMER_STATE_GQL,
} from "../../queries/CustomerQueries.gql";
import { CustomLink } from "../../support/CustomLink";
import { AddBankDocForCustomerView } from "../documents/bankStatements/AddBankDocForCustomerView";
import { CustomerBankDocsListView } from "./CustomerBankDocsListView";
import { CustomerProfileView } from "./CustomerProfileView";
import { EditCustomerView } from "./EditCustomerView";

export const CustomerDetailsView: React.FC<unknown> = () => {
  const navigate = useNavigate();
  const { customerId } = useParams<{ customerId: string }>();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation, userInfoByEmail } = userInfoState.context;

  // queries
  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetCustomerById, GetCustomerByIdVariables>(
    GET_CUSTOMER_BY_ID_GQL,
    {
      fetchPolicy: "network-only",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { customerId: customerId! },
    },
  );

  // refetch queries
  const refetchQueries: InternalRefetchQueryDescriptor[] = [
    {
      query: GET_CUSTOMER_BY_ID_GQL,
      variables: { customerId: customerId },
    },
  ];

  // mutation
  const [
    updateCustomerState,
    { loading: loadingM, error: errorM, reset: resetM },
  ] = useMutation<UpdateCustomerState, UpdateCustomerStateVariables>(
    UPDATE_CUSTOMER_STATE_GQL,
    { refetchQueries: refetchQueries },
  );

  // local state
  const [isOpen, setIsOpen] = useState(false);

  if (loadingQ || loadingM) {
    return <LottieLoading />;
  }

  if (errorQ || errorM) {
    console.error("CustomerDetailsView | CustomerDetailsView", {
      errorQ,
      errorM,
    });
    throw new Error("Error getting CustomerDetailsView");
  }

  const customerById = dataQ?.customerById;
  if (!customerById) {
    return <Alert type={"info"} label={LABELS.empty.none} />;
  }

  const { name, deletedAt } = customerById;
  const isActive = !deletedAt;
  const customerStateEnum = isActive
    ? CustomerStateEnum.DEACTIVATE
    : CustomerStateEnum.ACTIVATE;

  const messages = {
    activate: {
      title: "Activate Customer?",
      message:
        "You are about to activate this customer. All their data will be made visible now.",
    },
    deactivate: {
      title: "Deactivate Customer?",
      message:
        "You are about to deactivate a customer. All data will be hidden. You can always reactivate this customer later. Please note that you will still be charged for the license until the 12 month period has ended, at which point you can re-assign the license to another customer.",
    },
  };
  const modalView = (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <Dialog.Title
                  as={"h3"}
                  className={"text-lg font-medium leading-6 text-gray-900"}>
                  {isActive
                    ? messages.deactivate.title
                    : messages.activate.title}
                </Dialog.Title>
                <div className={"mt-2"}>
                  <p className={"text-gray-500"}>
                    {isActive
                      ? messages.deactivate.message
                      : messages.activate.message}
                  </p>
                </div>

                <div className={"my-8 flex justify-start space-x-4"}>
                  <BrandButton
                    colorType={"primary"}
                    label={"Confirm"}
                    onClick={closeModalWithConfirm}
                  />
                  <OutlineButton
                    colorType={"neutral"}
                    label={"Cancel"}
                    onClick={closeModal}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  const onToggleState = async () => {
    resetM();
    await updateCustomerState({
      variables: {
        updateCustomerStateInput: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          orgId: userInfoByEmail!.org!.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          locationId: currentLocation!.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          customerId: customerId!,
          customerStateEnum,
        },
      },
    });
  };

  function closeModal() {
    setIsOpen(false);
  }

  async function closeModalWithConfirm() {
    await onToggleState();
    closeModal();
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <PageLayout title={name}>
      <div className={"breadcrumbs text-sm"}>
        <ul>
          <li>
            <Link className={"link link-primary"} to={PATHS.customers()}>
              {LABELS.features.customers}
            </Link>
          </li>
          <li>{name}</li>
        </ul>
      </div>

      {!isActive && (
        <Alert type={"warning"} label={LABELS.warnings.customerInactive} />
      )}

      <div className={"flex items-center justify-between"}>
        <div className={"tabs my-8"}>
          <CustomLink to={ROUTE_NAMES.customer.profile}>
            {LABELS.features.profile}
          </CustomLink>
          <CustomLink to={ROUTE_NAMES.documents.bankDocs.index}>
            {LABELS.features.bankDocs}
          </CustomLink>
        </div>
        <div className={"flex items-center space-x-2"}>
          <OutlineButton
            colorType={"secondary"}
            label={"Edit"}
            onClick={() => navigate(ROUTE_NAMES.customer.edit)}
          />
          <Dropdown
            ButtonComponent={
              <IconButton
                colorType={"secondary"}
                IconSvg={ArrowChevronDownSvg}
              />
            }
            ListComponent={
              <>
                <li>
                  <div>
                    <button
                      className={"link link-secondary no-underline"}
                      disabled={loadingM}
                      onClick={openModal}>
                      {isActive ? "Deactivate" : "Activate"}
                    </button>
                  </div>
                  {modalView}
                </li>
              </>
            }
          />
        </div>
      </div>

      <Routes>
        <Route
          index
          element={
            <Navigate to={`./${ROUTE_NAMES.customer.profile}`} replace={true} />
          }
        />
        <Route
          path={ROUTE_NAMES.customer.profile}
          element={<CustomerProfileView customerById={customerById} />}
        />
        <Route
          path={ROUTE_NAMES.customer.edit}
          element={<EditCustomerView customerById={customerById} />}
        />
        <Route path={ROUTE_NAMES.documents.bankDocs.index} element={<Outlet />}>
          <Route
            index
            element={<CustomerBankDocsListView customerId={customerById.id} />}
          />
          <Route
            path={"add"}
            element={<AddBankDocForCustomerView customerById={customerById} />}
          />
        </Route>
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </PageLayout>
  );
};
