import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import { ProgressBar, SIZE } from "baseui/progress-bar";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import {
  DATE_FORMATS,
  getDateOnlyStr,
} from "../../../app/commonOps/CommonDateOps";
import { getFormattedCurrency } from "../../../app/commonOps/CurrencyOps";
import { INTERNAL, LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { ArrowChevronRightSvg } from "../../../components/svg/ArrowChevronRightSvg";
import {
  GetOrgBilling,
  GetOrgBillingVariables,
} from "../../../generated/operation-result-types";
import { GET_ORG_BILLING_GQL } from "../../../queries/OrgBillingQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { BillingUsageStatsView } from "./BillingUsageStatsView";

export const BillingView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Firm billing");
  });

  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orgId = userInfoByEmail!.org!.id;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetOrgBilling, GetOrgBillingVariables>(
    GET_ORG_BILLING_GQL,
    {
      fetchPolicy: "network-only",
      variables: {
        orgId: orgId,
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("BillingView | BillingView", { errorQ });
    throw new Error("Error getting BillingView");
  }

  const orgBilling = _.first(dataQ?.orgBillings?.edges)?.node;
  if (!orgBilling) {
    throw new Error("Missing billing info");
  }

  const {
    subscriptionPlan,
    paymentInfo,
    totalUsageSavings,
    billingCycleUsage,
    createdAt,
  } = orgBilling;

  const cardLast4 = paymentInfo.procCardInfo?.cardLast4
    ? `${LABELS.empty.cardMask}${paymentInfo.procCardInfo?.cardLast4}`
    : LABELS.empty.default;
  const cardInfo = `${
    paymentInfo.procCardInfo?.cardBrand ?? LABELS.empty.default
  } ${cardLast4}`;

  const paymentInfoView = (
    <FormSection name={"Billing"}>
      <div className={"flex items-center justify-between"}>
        <div className={"text-base-content opacity-60"}>Credit card</div>
        <div
          className={
            "text-md items-center font-semibold capitalize opacity-80"
          }>
          {cardInfo}
        </div>
      </div>

      <div className={"flex items-center justify-between"}>
        <div className={"text-base-content opacity-60"}>Current plan</div>
        <div
          className={
            "text-md items-center space-x-2 font-semibold capitalize opacity-80"
          }>
          <span>{subscriptionPlan.name}</span>
          <OutlineButton
            size={"small"}
            colorType={"primary"}
            label={"Change plan"}
            SvgIconRight={ArrowChevronRightSvg}
            onClick={() => navigate("../plans")}
          />
        </div>
      </div>

      <div className={"flex justify-between"}>
        <div className={"text-base-content opacity-60"}>
          Current price (Before tax)
        </div>
        <div className={"text-md font-semibold opacity-80"}>
          {getFormattedCurrency(
            subscriptionPlan.primaryAmount,
            subscriptionPlan.currency,
          )}{" "}
          {subscriptionPlan.primaryUnit}
        </div>
      </div>

      {subscriptionPlan.additionalAmount && (
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>
            Additional charges
          </div>
          <div className={"text-md font-semibold opacity-80"}>
            {getFormattedCurrency(
              subscriptionPlan.additionalAmount,
              subscriptionPlan.primaryUnit,
            )}{" "}
            {subscriptionPlan.additionalUnit}
          </div>
        </div>
      )}
    </FormSection>
  );

  const startDate = getDateOnlyStr(
    billingCycleUsage.upcomingInvoiceInfo.startDate,
    DATE_FORMATS.bankDateOnly,
  );
  const endDate = getDateOnlyStr(
    billingCycleUsage.upcomingInvoiceInfo.endDate,
    DATE_FORMATS.bankDateOnly,
  );
  const progressBar = (
    <div className={"py-0"}>
      <ProgressBar
        value={billingCycleUsage.totalBdCustomersUsed}
        maxValue={billingCycleUsage.totalBdCustomersPurchased}
        showLabel={false}
        size={SIZE.large}
      />
    </div>
  );

  const usageInfoView = (
    <FormSection name={"Current usage"}>
      <div className={"flex justify-between"}>
        <div className={"text-base-content opacity-60"}>
          Current billing period
        </div>
        <div className={"text-md font-semibold capitalize opacity-80"}>
          {startDate} - {endDate}
        </div>
      </div>

      <div className={"flex justify-between"}>
        <div className={"text-base-content opacity-60"}>
          Customer license usage
        </div>
        <div className={"flex flex-col items-end justify-center"}>
          <div className={"w-full"}>{progressBar}</div>
          <div className={"text-md mb-2 font-semibold capitalize opacity-80"}>
            Used {billingCycleUsage.totalBdCustomersUsed} of{" "}
            {billingCycleUsage.totalBdCustomersPurchased} customer licenses
          </div>
          {subscriptionPlan.primaryAmount !== 0 && (
            <OutlineButton
              size={"small"}
              colorType={"primary"}
              label={"Add Licenses"}
              SvgIconRight={ArrowChevronRightSvg}
              onClick={() => navigate("../licenses")}
            />
          )}
        </div>
      </div>

      <div className={"flex justify-between"}>
        <div className={"text-base-content opacity-60"}>
          Upcoming invoice total
        </div>
        <div className={"text-md font-semibold capitalize opacity-80"}>
          {getFormattedCurrency(
            billingCycleUsage.upcomingInvoiceInfo.amount,
            billingCycleUsage.upcomingInvoiceInfo.currency,
          )}
        </div>
      </div>
    </FormSection>
  );

  return (
    <>
      <BillingUsageStatsView
        totalPagesUsed={totalUsageSavings.totalPageCount}
        timeSaved={totalUsageSavings.totalTimeSaved}
        createdAt={createdAt}
      />
      {paymentInfoView}
      {usageInfoView}
      <a
        href={`mailto:${INTERNAL.emails.support}?subject=Cancellation%20request`}
        className={"btn btn-error btn-outline"}>
        Cancel subscription
      </a>
    </>
  );
};
