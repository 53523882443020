import React from "react";

import { FormSection } from "../../../support/FormSection";

const faqs = [
  {
    question: "What is a license?",
    answer: "1 license = 1 customer",
  },
  {
    question:
      "Do I still pay the same amount per month if I only do bookkeeping once a year?",
    answer:
      "Each customer license comes with a 12 month period, in other words, each license has a minimum of 12 payments. So, if you extract 12 months worth of statements in 1 day, you will still be charged the same amount per month for that particular customer.",
  },
  {
    question:
      "What happens if my customer doesn’t return? Do I still get charged for that customer?",
    answer:
      "You can deactivate the customer. Each deactivated customer license will expire automatically after 12 months (from the date of deactivation) so you won’t get charged unless you re-activate the license for that client.",
  },
  {
    question: "What happens when I deactivate a customer?",
    answer:
      "You are about to deactivate a customer. All data will be hidden. You can always reactivate their profile later. Please note that you will still be charged for the license until the 12 month period has ended, at which point you can re-assign the license to another customer.",
  },
  {
    question: "Does my plan include both bank and credit card statements?",
    answer:
      "Yes. Each license includes 24 statements per year — 12 bank statements and 12 credit card statements per customer.",
  },
  {
    question: "Do I get charged extra if I have multiple users in my firm?",
    answer: "No, our plan includes unlimited users in your firm.",
  },
  {
    question: "How many pages are included in my plan?",
    answer:
      "You can upload unlimited pages for every statement. Each license includes 24 statements per year — 12 bank statements and 12 credit card statements per customer.",
  },
  {
    question: "What’s included with the free onboarding?",
    answer:
      "Once you sign up with ProCharted, you have the option of to schedule a free onboarding call with one of our experts. We will walk you through setting up your software, extract your first statement and answer any questions you may have.",
  },
  {
    question: "What software do you export to?",
    answer:
      "We currently export to: Quickbooks desktop, QuickBooks online, Sage desktop, Sage Cloud, Xero and Excel / CSV.",
  },
  {
    question: "Does your product price include taxes?",
    answer: "No, sales tax are not included.",
  },
];

export const SubscriptionFaqsView: React.FC<unknown> = () => {
  const faqsView = faqs.map(({ question, answer }) => {
    return (
      <div
        className={
          "collapse-arrow collapse rounded-box border border-base-300"
        }>
        <input type={"checkbox"} />
        <div className={"collapse-title text-xl font-medium"}>{question}</div>
        <div className={"collapse-content"}>
          <p>{answer}</p>
        </div>
      </div>
    );
  });
  return (
    <FormSection name={"Billing FAQ"}>
      <div className={"mx-auto mb-8 w-full"}>{faqsView}</div>
    </FormSection>
  );
};
