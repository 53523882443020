import classNames from "classnames";
import React from "react";
import { Link, useMatch } from "react-router-dom";

import { INTERNAL, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { IconButton } from "../../components/button/IconButton";
import { ChevronDoubleLeftSvg } from "../../components/svg/ChevronDoubleLeftSvg";
import { HelpQuestionSvg } from "../../components/svg/HelpQuestionSvg";
import { sideNavRouteMap } from "../../pages/main/SidenavRouteMaps";
import { LocationSelector } from "./LocationSelector";

export const MainSidebarNav: React.FC<unknown> = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [isCollapsible, setIsCollapsible] = React.useState(false);

  // hide sidebar on bank doc details view
  const docPathMatch = useMatch({
    path: `${ROUTE_NAMES.documents.bankDoc.index}/:docId/*`,
  });
  const isDocPath = !!docPathMatch;

  React.useEffect(() => {
    if (isDocPath) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [setIsCollapsed, isDocPath]);

  // focus active menu
  // const router = useRouter();
  //
  // const activeMenu = useMemo(
  //   () => menuItems.find((menu) => menu.link === router.pathname),
  //   [router.pathname],
  // );

  const getNavItemClasses = () => {
    return classNames(
      // "flex items-center cursor-pointer hover:bg-light-lighter rounded w-full overflow-hidden whitespace-nowrap",
      " w-full block flex items-center rounded-lg bg-transparent text-gray-600",
      "focus:bg-gray-200 focus:font-semibold focus:text-primary focus:text-gray-900 focus:outline-none focus:shadow-outline",
      "hover:bg-gray-200 hover:text-gray-900",
    );
  };

  const onMouseOver = () => {
    setIsCollapsible(!isCollapsible);
  };

  const handleSidebarToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const sideNavRouteLinks = sideNavRouteMap.map((routeElement) => {
    const { name, icon: Icon } = routeElement;
    return (
      <div className={getNavItemClasses()} key={name}>
        <Link
          to={`/${name}`}
          className={"ml-1 flex h-full w-full items-center py-4 px-1"}>
          <div className={"w-8"}>
            <Icon />
          </div>
          {!isCollapsed && (
            <span className={classNames("text-md font-medium capitalize")}>
              {name}
            </span>
          )}
        </Link>
      </div>
    );
  });

  return (
    <div
      className={classNames(
        "flex max-h-screen flex-col justify-between overflow-y-auto px-4 pt-8 pb-4",
        {
          "w-96": !isCollapsed,
          "w-25": isCollapsed,
        },
      )}
      // style={{ transition: "width 300ms cubic-bezier(0.2, 0, 0, 1) 0s" }}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseOver}>
      <div className={"flex flex-col"}>
        <div className={"relative flex items-center justify-between"}>
          <div className={"flex items-center pl-1"}>
            <span>
              <LocationSelector isHidden={isCollapsed} />
            </span>
          </div>

          <IconButton
            colorType={"secondary"}
            size={"small"}
            IconSvg={ChevronDoubleLeftSvg}
            extraCx={classNames("rounded absolute right-0", {
              "rotate-180": isCollapsed,
            })}
            onClick={handleSidebarToggle}
          />
        </div>

        <div className={"mt-8 flex flex-col items-start"}>
          {sideNavRouteLinks}
        </div>
      </div>

      <div className={`${getNavItemClasses()}`}>
        <a
          className={"ml-1 flex h-full w-full items-center py-4 px-1"}
          href={`mailto:${INTERNAL.emails.support}?subject=Support%20request&body=ProCharted%20Support%2C%0A%0A`}>
          <div className={"w-8 items-center justify-center"}>
            <HelpQuestionSvg />
          </div>
          {!isCollapsed && (
            <span className={classNames("text-md font-medium capitalize")}>
              Support / Help
            </span>
          )}
        </a>
      </div>
    </div>
  );
};
