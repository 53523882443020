import React from "react";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";

export const CustomLink: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const tabBaseClass = "tab tab-bordered";
  const currentTabClass = match ? "tab-active" : "";

  return (
    <div>
      <Link className={`${tabBaseClass} ${currentTabClass}`} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
};
