import React from "react";

import logo from "../../media/proc-logo-bg-primary.png";

export const LogoText: React.FC<unknown> = () => {
  return (
    <>
      {/*<div className={"font-title inline-block text-3xl text-white"}>*/}
      {/*  <span className={"normal-case"}>ProCharted</span>*/}
      {/*</div>*/}
      <div>
        <img className={"h-12 w-auto object-contain"} src={logo} alt={"Logo"} />
      </div>
    </>
  );
};
