import _ from "lodash";

// functions
export const setEmptyOrObj = (val?: object) => {
  if (isEmptyObj(val)) return undefined;
  else return val;
};

export const isEmptyObj = (val?: object) => {
  return _.isEmpty(val);
};
