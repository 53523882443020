import { ROUTE_NAMES } from "../../app/constants/TextConstants";
import { CustomersSvg } from "../../components/svg/CustomersSvg";
import { DocumentSvg } from "../../components/svg/DocumentSvg";
import { HomeSvg } from "../../components/svg/HomeSvg";
import { SettingsSvg } from "../../components/svg/SettingsSvg";
import { CustomersView } from "../../features/customers/CustomersView";
import { CustomerView } from "../../features/customers/CustomerView";
import { BankDocView } from "../../features/documents/bankStatements/BankDocView";
import { DocumentsView } from "../../features/documents/DocumentsView";
import { HomeAuthView } from "../../features/home/HomeAuthView";
import { SignOutView } from "../../features/main/SignOutView";
import { SettingsView } from "../../features/settings/SettingsView";
import { UserView } from "../../features/user/UserView";

export const sideNavRouteMap = [
  { name: ROUTE_NAMES.home, component: HomeAuthView, icon: HomeSvg },
  // {
  //   name: ROUTE_NAMES.contacts,
  //   component: ContactsView,
  //   icon: ContactsSvg,
  // },
  {
    name: ROUTE_NAMES.customers.index,
    component: CustomersView,
    icon: CustomersSvg,
  },
  {
    name: ROUTE_NAMES.documents.index,
    component: DocumentsView,
    icon: DocumentSvg,
  },
  {
    name: ROUTE_NAMES.settings.index,
    component: SettingsView,
    icon: SettingsSvg,
  },
  // { name: "emails", component: EmailsView, icon: JobsSvg },
  // { name: "time", component: TimeView, icon: TimeSvg },
  // { name: "invoices", component: InvoicesView, icon: InvoicesSvg },
  // { name: "notifications", component: RemindersView, icon: NotificationSvg },
  // { name: ROUTE_NAMES.jobs, component: JobsView, icon: JobsSvg },
  // {
  //   name: ROUTE_NAMES.services,
  //   component: ServicesView,
  //   icon: ServicesSvg,
  // },
];

export const invisibleRouteMap = [
  { name: ROUTE_NAMES.customer.index, component: CustomerView },
  { name: ROUTE_NAMES.documents.bankDoc.index, component: BankDocView },
  { name: ROUTE_NAMES.signOut, component: SignOutView },
  { name: ROUTE_NAMES.user.index, component: UserView },
  // { name: ROUTE_NAMES.job, component: JobView },
];
