import { gql } from "@apollo/client";

export const GET_ORGS_GQL = gql`
  query GetOrgs {
    orgs {
      edges {
        node {
          id
          legalName
        }
      }
    }
  }
`;

export const GET_ORG_BY_ID_GQL = gql`
  query GetOrgById($orgId: ID!) {
    orgById(id: $orgId) {
      id
      legalName
    }
  }
`;

export const RENAME_ORG_GQL = gql`
  mutation RenameOrg($input: RenameOrgInput!) {
    renameOrg(input: $input) {
      org {
        id
      }
    }
  }
`;
