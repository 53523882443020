import { NetworkStatus } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import { useEffectOnce } from "usehooks-ts";
import { v4 } from "uuid";

import { setEmptyOrObj } from "../../app/commonOps/CommonObjectOps";
import { setEmptyOrStr } from "../../app/commonOps/CommonStringOps";
import { PAGE_LIMIT } from "../../app/constants/PaginationConstants";
import { LABELS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { OutlineButton } from "../../components/button/OutlineButton";
import { EmptyDataView } from "../../components/emptyStates/EmptyDataView";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import { ModalWithClose } from "../../components/modal/ModalWithClose";
import { AddStackSvg } from "../../components/svg/AddStackSvg";
import {
  BankDocFilterInput,
  BankDocSortInput,
  GetBankDocsByLocation,
  GetBankDocsByLocationVariables,
  SortEnumType,
} from "../../generated/operation-result-types";
import { GET_BANK_DOCS_BY_LOCATION_GQL } from "../../queries/BankDocQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";
import { GetSharedBankDocsTableView } from "../documents/bankStatements/shared/BankDocsShared";

type Props = { customerId: string };

export const CustomerBankDocsListView: React.FC<Props> = ({ customerId }) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Customer bank statements");
  });

  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  // state - sorting
  type SortOrderT = "customerName" | "createdAt" | "uploadedByUserName";
  const [selectedSortOrder, setSelectedSortOrder] =
    React.useState<SortOrderT>("createdAt");

  const querySortOrder = match(selectedSortOrder)
    .with("customerName", (): BankDocSortInput => {
      return { customer: { name: SortEnumType.ASC } };
    })
    .with("createdAt", (): BankDocSortInput => {
      return { createdAt: SortEnumType.DESC };
    })
    .with("uploadedByUserName", (): BankDocSortInput => {
      return {
        uploadedByUser: {
          userProfile: {
            firstName: SortEnumType.ASC,
          },
        },
      };
    })
    .exhaustive();

  // state - filtering
  type FilterStateT = {
    customerId?: string;
    createdAt?: string;
    uploadedByUserName?: string;
    status?: string;
    showInactive?: boolean;
  };
  const initialFilterState: BankDocFilterInput = {
    customer: { id: { eq: customerId } },
    deletedAt: {
      eq: null,
    },
  };
  const [filterState, setFilterState] = React.useState(initialFilterState);

  // RHF
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FilterStateT>({
    defaultValues: {},
  });

  // functions - filter
  const filterModalId = v4();

  const filterModalCheckRef = React.createRef<HTMLInputElement>();

  const filterOnSubmit: SubmitHandler<FilterStateT> = (data) => {
    setFilterState((prevState): BankDocFilterInput => {
      return {
        ...prevState,
        createdAt: data.createdAt ? { lte: data.createdAt } : undefined,
        bankDocStatusEnum: data.status
          ? { statusType: { contains: data.status } }
          : undefined,
        uploadedByUser: data.uploadedByUserName
          ? {
              userProfile: { firstName: { contains: data.uploadedByUserName } },
            }
          : undefined,
        deletedAt: data.showInactive ? { neq: null } : { eq: null },
      };
    });
  };
  const clearFilterState = () => {
    setFilterState(initialFilterState);
    reset();
  };
  React.useEffect(() => {
    if (filterModalCheckRef.current) {
      filterModalCheckRef.current.checked = false;
    }
  }, [filterModalCheckRef, filterState]);

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
    fetchMore,
    networkStatus,
  } = Apollo.useQuery<GetBankDocsByLocation, GetBankDocsByLocationVariables>(
    GET_BANK_DOCS_BY_LOCATION_GQL,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        locationId: currentLocation!.id,
        first: PAGE_LIMIT,
        order: [querySortOrder],
        where: setEmptyOrObj(filterState),
      },
    },
  );

  const isRefetching = networkStatus === NetworkStatus.refetch;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoadingOrFetching = (loadingQ && !dataQ) || isRefetching;

  if (errorQ) {
    console.error("BankDocsListView | BankDocsListView", errorQ);
    throw new Error("Error getting CustomerBankDocsListView");
  }

  if (isLoadingOrFetching) {
    return <LottieLoading />;
  }

  const bankDocs = dataQ?.bankDocsByLocation;
  const bankDocEdges = bankDocs?.edges;
  const edgesEmpty = !bankDocEdges || bankDocEdges.length < 1;

  const loadMore = async () => {
    await fetchMore({
      variables: {
        first: PAGE_LIMIT,
        after: bankDocs?.pageInfo.endCursor,
      },
    });
  };

  const filterBtnView = (
    <div>
      <ModalWithClose
        ref={filterModalCheckRef}
        modalId={filterModalId}
        modalButtonLabel={LABELS.filters.name}>
        <div className={"py-8"}>
          <FormSection name={LABELS.filters.name}>
            <form
              onSubmit={handleSubmit(filterOnSubmit)}
              className={"flex flex-col items-center"}>
              <div
                className={
                  "form-control grid w-full grid-cols-2 items-center pb-2"
                }>
                <label className={"label"}>
                  <span className={"label-text"}>Uploaded at</span>
                </label>
                <input
                  className={"input input-bordered"}
                  {...register("createdAt", {
                    required: false,
                    setValueAs: setEmptyOrStr,
                  })}
                  placeholder={"YYYY-MM-DD"}
                />
                {errors?.createdAt?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.createdAt?.message}
                  </span>
                )}
              </div>

              <div
                className={
                  "form-control grid w-full grid-cols-2 items-center pb-2"
                }>
                <label className={"label"}>
                  <span className={"label-text"}>Status</span>
                </label>
                <input
                  className={"input input-bordered"}
                  {...register("status", {
                    required: false,
                    setValueAs: setEmptyOrStr,
                  })}
                  placeholder={"Ex: Completed or In progress etc."}
                />
                {errors?.status?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.status?.message}
                  </span>
                )}
              </div>

              <div
                className={
                  "form-control grid w-full grid-cols-2 items-center pb-2"
                }>
                <label className={"label"}>
                  <span className={"label-text"}>
                    {LABELS.features.user} name
                  </span>
                </label>
                <input
                  className={"input input-bordered"}
                  {...register("uploadedByUserName", {
                    required: false,
                    setValueAs: setEmptyOrStr,
                  })}
                />
                {errors?.uploadedByUserName?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.uploadedByUserName?.message}
                  </span>
                )}
              </div>

              <div
                className={
                  "form-control grid w-full grid-cols-2 items-center pb-2"
                }>
                <label className={"label"}>
                  <span className={"label-text"}>
                    Only show {LABELS.deletion.inactive}
                  </span>
                </label>

                <input
                  className={"checkbox checkbox-primary"}
                  type={"checkbox"}
                  {...register("showInactive")}
                />
                {errors?.showInactive?.message && (
                  <span className={"pt-2 text-sm font-bold text-error"}>
                    {errors?.showInactive?.message}
                  </span>
                )}
              </div>

              <div className={"space-x-2 pt-2"}>
                <OutlineButton
                  buttonType={"submit"}
                  colorType={"primary"}
                  label={LABELS.filters.apply}
                />
                <OutlineButton
                  colorType={"secondary"}
                  label={LABELS.filters.reset}
                  onClick={clearFilterState}
                />
              </div>
            </form>
          </FormSection>
        </div>
      </ModalWithClose>
    </div>
  );
  const sortBtnView = (
    <div className={"dropdown-end dropdown"}>
      <div tabIndex={0} className={"btn btn-outline m-1"}>
        {LABELS.sort.name}
      </div>
      <ul
        tabIndex={0}
        className={
          "dropdown-content menu rounded-box w-52 bg-base-100 p-2 shadow"
        }>
        <li>
          <div
            onClick={(e) => {
              e.preventDefault();
              setSelectedSortOrder("createdAt");
            }}>
            Upload Date
          </div>
        </li>
        <li>
          <div
            onClick={(e) => {
              e.preventDefault();
              setSelectedSortOrder("customerName");
            }}>
            {LABELS.features.customer}
          </div>
        </li>
        <li>
          <div
            onClick={(e) => {
              e.preventDefault();
              setSelectedSortOrder("uploadedByUserName");
            }}>
            {LABELS.features.user}
          </div>
        </li>
      </ul>
    </div>
  );
  const paginationView = (
    <div className={"flex justify-end py-8"}>
      <div className={"btn-group"}>
        {bankDocs?.pageInfo.hasNextPage && (
          <OutlineButton
            colorType={"secondary"}
            label={`Load ${PAGE_LIMIT} more`}
            onClick={loadMore}
            disabled={isFetchingMore}
          />
        )}
      </div>
    </div>
  );

  const bankDocsTableView = (
    <>{GetSharedBankDocsTableView(bankDocEdges ?? [])}</>
  );

  return (
    <FormSection name={LABELS.features.bankDocs}>
      <div className={"flex justify-end pb-4"}>
        <OutlineButton
          colorType={"link"}
          label={"Upload"}
          SvgIconLeft={AddStackSvg}
          onClick={() => navigate("./add")}
        />
      </div>
      <div
        className={
          "card card-body my-6 overflow-x-auto border-base-300 bg-base-100 px-0"
        }>
        <div className={"flex justify-between pb-4"}>
          {filterBtnView}
          {sortBtnView}
        </div>

        {edgesEmpty ? <EmptyDataView /> : bankDocsTableView}

        {paginationView}
      </div>
    </FormSection>
  );
};
