import { useMutation } from "@apollo/client";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffectOnce } from "usehooks-ts";

import { getFormattedCurrency } from "../../../app/commonOps/CurrencyOps";
import { LABELS } from "../../../app/constants/TextConstants";
import { Alert } from "../../../components/alerts/alert";
import { BrandButton } from "../../../components/button/BrandButton";
import {
  GetSubscriptionPlans_orgBillings_edges_node_paymentInfo,
  GetSubscriptionPlans_orgBillings_edges_node_subscriptionPlans,
  SwitchBdSubscriptionInput,
  SwitchBdSubscriptionPlan,
  SwitchBdSubscriptionPlanVariables,
} from "../../../generated/operation-result-types";
import { SWITCH_BD_PLAN_GQL } from "../../../queries/OrgBillingQueries.gql";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { StripeSetSubscription } from "./StripeSubscription";

type Props = {
  selectedPlan: GetSubscriptionPlans_orgBillings_edges_node_subscriptionPlans;
  paymentInfo: GetSubscriptionPlans_orgBillings_edges_node_paymentInfo;
  orgId: string;
  onSuccess: () => void;
};
export const SubscriptionPlanDetailsView: React.FC<Props> = ({
  orgId,
  selectedPlan,
  paymentInfo,
  onSuccess,
}) => {
  useEffectOnce(() => {
    document.title = GetPageTitle("Subscription plan details");
  });

  // props
  const { name, primaryAmount, currency } = selectedPlan;
  const { procCardInfo } = paymentInfo;

  // app insights
  const appInsights = useAppInsightsContext();
  const trackBdSwitchedPlan = useTrackEvent(
    appInsights,
    "Subscription plan switched",
    {},
  );

  const minCustomerQuantity = 2;
  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    watch,
  } = useForm<SwitchBdSubscriptionInput>({
    defaultValues: {
      orgId,
      newSubscriptionPlanId: selectedPlan.id,
      customerQuantity: minCustomerQuantity,
    },
    mode: "onChange",
  });

  // gql
  const [switchPlan, { loading: loadingM, error: errorM, reset: resetM }] =
    useMutation<SwitchBdSubscriptionPlan, SwitchBdSubscriptionPlanVariables>(
      SWITCH_BD_PLAN_GQL,
      {},
    );

  // watch and change price
  const [amountDueNow, setAmountDueNow] = React.useState(
    getFormattedCurrency(primaryAmount),
  );
  const watchCustomerQuantity = watch("customerQuantity");
  React.useEffect(() => {
    if (watchCustomerQuantity >= minCustomerQuantity) {
      setAmountDueNow(
        getFormattedCurrency(primaryAmount * watchCustomerQuantity),
      );
    }
  }, [primaryAmount, watchCustomerQuantity]);

  const onSubmit: SubmitHandler<SwitchBdSubscriptionInput> = async (data) => {
    resetM();
    await switchPlan({
      variables: {
        switchBdSubscriptionInput: data,
      },
    });
    trackBdSwitchedPlan({
      orgId: orgId,
      customerQuantity: data.customerQuantity,
      newSubscriptionPlanId: data.newSubscriptionPlanId,
    });
    onSuccess();
  };

  const cardAlreadyExists = !!procCardInfo?.cardLast4;
  const [cardAdded, setCardAdded] = React.useState(cardAlreadyExists);

  React.useEffect(() => {
    if (cardAdded) {
      setFocus("customerQuantity");
    }
  }, [cardAdded, setFocus]);

  if (!cardAdded) {
    return (
      <StripeSetSubscription
        publishableKey={paymentInfo.clientPublishableKey}
        setupIntentId={paymentInfo.setupIntentId}
        onSuccess={() => setCardAdded(true)}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection name={"Selected Plan"}>
        <div className={"flex flex-col"}>
          <span>
            → Subscribing to <span className={"font-bold"}>{name}</span>
          </span>
          <div className={"form-control"}>
            <label className={"label"}>
              <span className={"label-text"}>Customer license quantity</span>
            </label>
            <input
              type={"number"}
              {...register("customerQuantity", {
                min: {
                  value: minCustomerQuantity,
                  message: `License quantity must be at least ${minCustomerQuantity}`,
                },
                valueAsNumber: true,
                required: {
                  value: true,
                  message: "License quantity is required",
                },
              })}
              className={"input input-bordered"}
            />
            {errors?.customerQuantity?.message && (
              <span className={"pt-2 text-sm font-bold text-error"}>
                {errors?.customerQuantity?.message}
              </span>
            )}
          </div>
          <div className={"py-2"}>
            → Monthly subscription cost{" "}
            <span className={"font-bold"}>
              {amountDueNow} {currency}
            </span>
          </div>
        </div>

        {errorM && <Alert type={"error"} label={LABELS.errors.default} />}

        {cardAdded && (
          <BrandButton
            buttonType={"submit"}
            colorType={"primary"}
            label={"Confirm plan"}
            disabled={loadingM}
          />
        )}
      </FormSection>
    </form>
  );
};
