import { gql } from "@apollo/client";

export const GET_LOCATIONS_BY_ORG_ID_GQL = gql`
  query GetLocationsByOrgId($orgId: ID!) {
    orgById(id: $orgId) {
      id
      legalName
      locations {
        edges {
          node {
            id
            locationAddress {
              id
              line1
              line2
              city
              region
              country
              postalCode
            }
          }
        }
      }
    }
  }
`;
