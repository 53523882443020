import React from "react";

import { getDateOnlyStr } from "../../../app/commonOps/CommonDateOps";
import { TW_SIZES } from "../../../app/constants/CssConstants";
import { IconButton } from "../../../components/button/IconButton";
import { DocumentSvg } from "../../../components/svg/DocumentSvg";
import { InfoSvg } from "../../../components/svg/InfoSvg";
import { TimeSvg } from "../../../components/svg/TimeSvg";

type Props = {
  totalPagesUsed: number;
  timeSaved: number;
  createdAt: string;
};

export const BillingUsageStatsView: React.FC<Props> = ({
  totalPagesUsed,
  timeSaved,
  createdAt,
}) => {
  const createdDate = getDateOnlyStr(createdAt);
  return (
    <div className={"stats my-4 w-full shadow"}>
      <div className={"stat hover:text-primary"}>
        <div className={"stat-figure stroke-2"}>
          <DocumentSvg sizeCx={TW_SIZES.hw.xl} />
        </div>
        <div className={"stat-title"}>Total pages converted</div>
        <div className={"stat-value"}>{totalPagesUsed}</div>
        <div className={"stat-desc"}>Since {createdDate}</div>
      </div>
      <div className={"stat hover:text-primary"}>
        <div className={"stat-figure stroke-2"}>
          <TimeSvg sizeCx={TW_SIZES.hw.xl} />
        </div>
        <div className={"stat-title"}>Total time saved</div>
        <div className={"stat-value"}>{timeSaved}</div>
        <div className={"stat-desc flex items-center"}>
          Hours since {createdDate}
          <div
            className={"tooltip tooltip-right tooltip-secondary"}
            data-tip={"2 pages takes 1 hour for data entry"}>
            <IconButton
              size={"extra-small"}
              colorType={"secondary"}
              border={false}
              extraCx={"ml-1 rounded-full"}
              IconSvg={InfoSvg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
