// eslint-disable-next-line no-var
let GLOBAL_AUTH_TOKEN: string | undefined = undefined;

export function setAuthToken(newToken: string) {
  GLOBAL_AUTH_TOKEN = newToken;
}

export function getAuthHeader(): { Authorization: string } {
  return {
    Authorization: `Bearer ${GLOBAL_AUTH_TOKEN}`,
  };
}
