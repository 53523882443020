import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { setEmptyOrObj } from "../../app/commonOps/CommonObjectOps";
import { PAGE_LIMIT } from "../../app/constants/PaginationConstants";
import { LABELS, PATHS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { Alert } from "../../components/alerts/alert";
import { OutlineButton } from "../../components/button/OutlineButton";
import { EmptyDataView } from "../../components/emptyStates/EmptyDataView";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  BankDocFilterInput,
  GetBankDocsByLocation,
  GetBankDocsByLocationVariables,
  SortEnumType,
} from "../../generated/operation-result-types";
import { GET_BANK_DOCS_BY_LOCATION_GQL } from "../../queries/BankDocQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetSharedBankDocsTableView } from "../documents/bankStatements/shared/BankDocsShared";
import { HomeBankDocsAggChartView } from "./HomeBankDocsAggChartView";

export const HomeBankDocsListView: React.FC<unknown> = () => {
  const navigate = useNavigate();

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const locationId = currentLocation!.id;

  const initialFilterState: BankDocFilterInput = {
    deletedAt: {
      eq: null,
    },
  };

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<GetBankDocsByLocation, GetBankDocsByLocationVariables>(
    GET_BANK_DOCS_BY_LOCATION_GQL,
    {
      fetchPolicy: "network-only",
      variables: {
        locationId: locationId,
        first: PAGE_LIMIT,
        order: [{ createdAt: SortEnumType.DESC }],
        where: setEmptyOrObj(initialFilterState),
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    throw new Error("Error getting HomeBankDocsListView");
  }

  const bankDocEdges = dataQ?.bankDocsByLocation?.edges;
  const edgesEmpty = !bankDocEdges || bankDocEdges.length < 1;
  if (!bankDocEdges) {
    return <Alert type={"info"} label={LABELS.empty.none} />;
  }

  const bankDocsTableView = (
    <>{GetSharedBankDocsTableView(bankDocEdges ?? [])}</>
  );

  return (
    <>
      <FormSection name={"Recently Uploaded Documents"}>
        <HomeBankDocsAggChartView locationId={locationId} />
        <div
          className={
            "card card-body my-6 overflow-x-auto border-base-300 bg-base-100 px-0"
          }>
          {edgesEmpty ? <EmptyDataView /> : bankDocsTableView}
        </div>
        <div className={"flex justify-end"}>
          <OutlineButton
            colorType={"primary"}
            label={"View all"}
            onClick={() => navigate(PATHS.documents())}
          />
        </div>
      </FormSection>
    </>
  );
};
