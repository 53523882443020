import React from "react";
import { Link } from "react-router-dom";

import { LABELS, PATHS } from "../../../../app/constants/TextConstants";
import { AvatarPlaceholder } from "../../../../components/avatar/AvatarPlaceholder";
import { Badge } from "../../../../components/badge/Badge";
import { IconButton } from "../../../../components/button/IconButton";
import { WarningExclaimSvg } from "../../../../components/svg/WarningExclaimSvg";
import { GetBankDocsByLocation_bankDocsByLocation_edges } from "../../../../generated/operation-result-types";

export const GetBankDocSharedStatusBadge = (status?: string): JSX.Element => {
  if (status?.toLowerCase().startsWith("pending")) {
    return <Badge label={"Pending"} colorType={"info"} />;
  }

  if (status?.toLowerCase().startsWith("failure")) {
    return <Badge label={"Failed"} colorType={"error"} />;
  }

  if (status?.toLowerCase().startsWith("success")) {
    return <Badge label={"Completed"} colorType={"success"} />;
  }

  return <Badge label={"Unknown"} colorType={"warning"} />;
};

export const GetSharedBankDocsTableView = (
  bankDocEdges: GetBankDocsByLocation_bankDocsByLocation_edges[],
) => {
  const rowsView = bankDocEdges.map((bankDocEdge) => {
    const {
      id: bankDocId,
      name,
      customer,
      createdAt,
      deletedAt,
      bankDocStatusEnum,
      uploadedByUser,
      metaBankName,
      metaPageCount,
    } = bankDocEdge.node;
    const isActive = !deletedAt;
    const derivedUploadDate = new Date(createdAt).toLocaleDateString();
    const derivedStatusBadge = GetBankDocSharedStatusBadge(
      bankDocStatusEnum?.statusType,
    );

    return (
      <tr key={bankDocId}>
        <td>
          <div className={"flex items-center space-x-3"}>
            <Link
              to={PATHS.bankDocById(bankDocId)}
              className={"link link-primary text-sm font-semibold uppercase"}>
              {name}
            </Link>
            {!isActive && (
              <div
                className={"tooltip tooltip-bottom tooltip-warning"}
                data-tip={LABELS.warnings.inactiveTooltip}>
                <IconButton
                  size={"extra-small"}
                  colorType={"secondary"}
                  border={false}
                  extraCx={"ml-1 mt-1 rounded-full"}
                  IconSvg={WarningExclaimSvg}
                />
              </div>
            )}
          </div>
        </td>
        <td>
          <div className={"flex items-center"}>
            <Link
              to={PATHS.customerById(customer?.id)}
              className={"link link-primary text-sm font-semibold uppercase"}>
              {customer?.name}
            </Link>
          </div>
        </td>
        <td>
          <div className={"flex items-center"}>{derivedStatusBadge}</div>
        </td>
        <td>
          <div className={"flex flex-col justify-center"}>
            <div className={"text-sm"}>{`${metaBankName ?? ""}`}</div>
            <div className={"text-xs text-opacity-50"}>
              {metaPageCount ?? ""}
            </div>
          </div>
        </td>
        <td>
          <div className={"flex items-center space-x-3"}>
            <AvatarPlaceholder
              size={"extra-small"}
              label={
                uploadedByUser?.userProfile?.avatarInitials ??
                LABELS.unknownUser.defaultInitials
              }
            />
            <div>
              <div className={"text-sm"}>{`${
                uploadedByUser?.userProfile?.fullName ?? ""
              }`}</div>
              <div className={"text-xs text-opacity-50"}>
                {derivedUploadDate}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <table className={"table w-full"}>
      <thead>
        <tr>
          <th>{LABELS.features.document} Name</th>
          <th>{LABELS.features.customer} Name</th>
          <th>Status</th>
          <th>Bank / pages</th>
          <th>Uploaded by</th>
        </tr>
      </thead>
      <tbody>{rowsView}</tbody>
    </table>
  );
};
