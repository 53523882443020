import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { SyntheticEvent, useState } from "react";

import { Alert } from "../../../components/alerts/alert";
import { BrandButton } from "../../../components/button/BrandButton";
import { StripeCardSection } from "./StripeCardSection";

type Props = {
  setupIntentId: string;
  onSuccess: () => void;
};

export const StripeCheckoutForm: React.FC<Props> = ({
  setupIntentId,
  onSuccess,
}) => {
  // stripe
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const stripeCardElement = elements.getElement(CardElement);
    if (!stripeCardElement) {
      return;
    }

    setIsSubmitted(true);
    const { error } = await stripe.confirmCardSetup(setupIntentId, {
      // todo: redirect to server?
      return_url: "http://localhost:3000/settings/billing",
      payment_method: {
        card: stripeCardElement,
      },
    });
    setIsSubmitted(false);

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      onSuccess();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className={"flex w-1/2 flex-col justify-center"}>
        <StripeCardSection />

        <BrandButton
          colorType={"primary"}
          buttonType={"submit"}
          disabled={!stripe || isSubmitted}
          label={"add & save card"}
        />
      </div>
      {errorMessage && <Alert type={"error"} label={errorMessage} />}
    </form>
  );
};
