import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { ROUTE_IDS } from "../../app/constants/TextConstants";
import { CustomerDetailsView } from "./CustomerDetailsView";

export const CustomerView: React.FC<unknown> = () => {
  return (
    <>
      <Routes>
        <Route
          path={`:${ROUTE_IDS.customerId}/*`}
          element={<CustomerDetailsView />}
        />
        <Route path={"*"} element={<>Not found</>} />
      </Routes>

      <div>
        <Outlet />
      </div>
    </>
  );
};
