import * as Apollo from "@apollo/client";
import * as Xstate from "@xstate/react";
import React from "react";

import { LABELS } from "../../../app/constants/TextConstants";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import { Alert } from "../../../components/alerts/alert";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  GetCustomersByLocationForAdd,
  GetCustomersByLocationForAddVariables,
} from "../../../generated/operation-result-types";
import { GET_CUSTOMERS_BY_LOCATION_FOR_ADD_GQL } from "../../../queries/CustomerQueries.gql";
import { AddBankDocView } from "./AddBankDocView";

export const AddBankDocGlobalView: React.FC<unknown> = () => {
  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { currentLocation } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = Apollo.useQuery<
    GetCustomersByLocationForAdd,
    GetCustomersByLocationForAddVariables
  >(GET_CUSTOMERS_BY_LOCATION_FOR_ADD_GQL, {
    fetchPolicy: "network-only",
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { locationId: currentLocation!.id },
  });

  if (loadingQ) {
    return <LottieLoading />;
  }

  if (errorQ) {
    console.error("AddBankDocGlobalView | AddBankDocGlobalView", { errorQ });
    throw new Error("Error getting AddBankDocGlobalView");
  }

  const customers = dataQ?.customers?.edges;
  if (!customers) {
    return <Alert type={"info"} label={LABELS.empty.none} />;
  }

  return (
    <>
      <AddBankDocView customers={customers} />
    </>
  );
};
