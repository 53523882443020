import { match } from "ts-pattern";

type Environment = "azProcProd01";
const PROC_ENV = "azProcProd01" as Environment;

const procProdConstants = {
  b2cTenant: {
    domainName: "1BD2636BAA29466D9F96.onmicrosoft.com",
    authorityDomain: "1BD2636BAA29466D9F96.b2clogin.com",
  },
  dotnetAuthApi01: {
    clientId: "f422db84-ed3b-4bd8-b8b6-d462cd76945b",
    defaultScopeName: "demo.read",
  },
  msalReactSpa01: {
    clientId: "252e6e86-dd5b-4fce-b1e9-baa1c46cbf94",
  },
  userFlowSignIn: {
    name: "B2C_1_signin1",
  },
  apiService: {
    baseUrl:
      "https://prod-capp-public-api-01.bluemushroom-217ccbac.canadacentral.azurecontainerapps.io",
  },
};

const azureConnectionStringsForEnv = {
  appInsights: {
    azProcProd01Value:
      "InstrumentationKey=50589612-86c8-483b-85f8-8bff63570d26;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/",
  },
};

const reactSpaClientIdsForEnv = {
  azProcProd01Value: procProdConstants.msalReactSpa01.clientId,
};

const serverBaseUrlForEnv = {
  azProcProd01Value: procProdConstants.apiService.baseUrl,
};

const getSigninFlowUrl = (): string => {
  // Example: "https://XYZ123.b2clogin.com/XYZ123.onmicrosoft.com/B2C_1_signin1"
  return `https://${procProdConstants.b2cTenant.authorityDomain}/${procProdConstants.b2cTenant.domainName}/${procProdConstants.userFlowSignIn.name}`;
};

export const getScopes = (): [string] => {
  // Example: `https://XYZ123.onmicrosoft.com/abcd-uuid-1234/demo.read`
  return [
    `https://${procProdConstants.b2cTenant.domainName}/${procProdConstants.dotnetAuthApi01.clientId}/${procProdConstants.dotnetAuthApi01.defaultScopeName}`,
  ];
};

export const getClientIdForEnv = (
  currentEnv: Environment = PROC_ENV,
): string => {
  return match(currentEnv)
    .with("azProcProd01", () => reactSpaClientIdsForEnv.azProcProd01Value)
    .exhaustive();
};

export const getAzAiCsForEnv = (): string => {
  return match(PROC_ENV)
    .with(
      "azProcProd01",
      () => azureConnectionStringsForEnv.appInsights.azProcProd01Value,
    )
    .exhaustive();
};

export const getServerBaseUrlForEnv = (): string => {
  return match(PROC_ENV)
    .with("azProcProd01", () => serverBaseUrlForEnv.azProcProd01Value)
    .exhaustive();
};

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: procProdConstants.userFlowSignIn.name,
  },
  authorities: {
    signUpSignIn: {
      authority: getSigninFlowUrl(),
    },
  },
  authorityDomain: procProdConstants.b2cTenant.authorityDomain,
};
