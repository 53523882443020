import { useQuery } from "@apollo/client";
import * as Xstate from "@xstate/react";
import _ from "lodash";
import React from "react";
import { useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../app/constants/TextConstants";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { LottieLoading } from "../../components/graphics/LottieLoading";
import {
  GetUserInfoByEmail,
  GetUserInfoByEmailVariables,
} from "../../generated/operation-result-types";
import { GET_USER_INFO_BY_EMAIL_GQL } from "../../queries/UserQueries.gql";
import { FormSection } from "../../support/FormSection";
import { GetPageTitle } from "../../support/ScrollToTop";

export const UserProfileView: React.FC<unknown> = () => {
  useEffectOnce(() => {
    document.title = GetPageTitle("User profile");
  });

  // xstate
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const {
    loading: loadingQ,
    error: errorQ,
    data: dataQ,
  } = useQuery<GetUserInfoByEmail, GetUserInfoByEmailVariables>(
    GET_USER_INFO_BY_EMAIL_GQL,
    {
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        email: userInfoByEmail!.userProfile!.email,
      },
    },
  );

  if (loadingQ) {
    return <LottieLoading />;
  }
  if (errorQ) {
    console.error("UserProfileView", { errorQ });
    throw new Error("Error getting UserProfileView");
  }

  const userResponse = _.first(dataQ?.users?.edges)?.node;
  if (!userResponse) {
    throw new Error("Missing user in UserProfileView");
  }

  return (
    <>
      <FormSection name={LABELS.features.profile}>
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Name</div>
          <div className={"text-md font-semibold opacity-80"}>
            {userResponse.userProfile?.fullName}
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div className={"text-base-content opacity-60"}>Email</div>
          <div className={"text-md font-semibold opacity-80"}>
            {userResponse.userProfile?.email}
          </div>
        </div>
      </FormSection>
      <FormSection name={"Change password"}>
        <div className={"text-base-content opacity-80"}>
          Follow these steps to change your password:
        </div>
        <div className={"text-md opacity-80"}>
          <li>Sign-out of ProCharted</li>
          {/* eslint-disable-next-line */}
          <li>{'Use "forgot my password" link to reset'}</li>
        </div>
      </FormSection>
    </>
  );
};
