import {
  AxisModel,
  Category,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  FontModel,
  Inject,
  Legend,
  LegendSettingsModel,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  TooltipSettingsModel,
} from "@syncfusion/ej2-react-charts";
import React from "react";

export type ChartDataPoint = {
  eventDate: Date;
  eventCount: number;
};
export type ChartDataPoints = Array<ChartDataPoint>;
export type Props = {
  chartName: string;
  dataPoints: ChartDataPoints;
};

export const BarChartSf01: React.FC<Props> = ({ dataPoints, chartName }) => {
  // const data = [
  //   { dueDate: "Today", jobsDue: 0 },
  //   { dueDate: "Apr 15", jobsDue: 1 },
  //   { dueDate: "Sep 14", jobsDue: 1 },
  //   { dueDate: "Next year", jobsDue: 1 },
  // ];
  const fontFam: FontModel = { fontFamily: "Arial" };
  const fontTextStyle: FontModel = { ...fontFam, color: "#070808" };
  const palette = ["#3D4AFF", "#F6B53F", "#6FAAB0", "#C4C24A"];
  const primaryXAxis: AxisModel = {
    valueType: "Category",
    // title: "Countries",
    labelStyle: { ...fontTextStyle, opacity: 0 },
    titleStyle: fontFam,
    majorGridLines: { width: 0 },
  };
  const primaryYAxis: AxisModel = {
    minimum: 0,
    // maximum: 5,
    interval: 1,
    labelStyle: { ...fontTextStyle },
    titleStyle: fontFam,
  };
  const tooltip: TooltipSettingsModel = {
    enable: true,
    shared: false,
    textStyle: fontFam,
  };
  const legendSettings: LegendSettingsModel = {
    visible: true,
    textStyle: fontFam,
  };

  return (
    <>
      <ChartComponent
        id={"barChart"}
        primaryXAxis={primaryXAxis}
        primaryYAxis={primaryYAxis}
        palettes={palette}
        tooltip={tooltip}
        legendSettings={legendSettings}>
        <Inject
          services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={dataPoints}
            xName={"eventDate"}
            yName={"eventCount"}
            name={chartName}
            type={"Column"}
            legendShape={"Circle"}
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </>
  );
};
